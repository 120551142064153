import OfficeList from "../pages";
import AddUpdateOffice from "../pages/add_update";
import React from "react";

interface RouteObject {
  path: string;
  component: React.ComponentType<any>;
  exact?: boolean;
}

const officeRoutes: Array<RouteObject> = [
  {path: '/apps-offices-list', component: OfficeList},
  {path: '/apps-offices-list/add', component: AddUpdateOffice},
  {path: '/apps-offices-list/update/:id', component: AddUpdateOffice},
];

export default officeRoutes;
