import { createSlice } from "@reduxjs/toolkit";
import { addEmployee, deleteEmployee, getEmployee, getSingleEmployee, updateEmployee } from "./thunk";
import { RootState } from "../../../../slices";

export const initialState = {
  employeesList: [],
  errors: {}
};

const EmployeesSlice = createSlice({
  name: "Employees",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEmployee.fulfilled, (state: any, action: any) => {
      state.employeesList = action.payload;
    });
    builder.addCase(getEmployee.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getSingleEmployee.fulfilled, (state: any, action: any) => {
      state.employeesList = action.payload;
    });
    builder.addCase(getSingleEmployee.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(addEmployee.fulfilled, (state: any, action: any) => {
      state.employeesList?.unshift(action.payload);
    });
    builder.addCase(addEmployee.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(updateEmployee.fulfilled, (state: any, action: any) => {
      state.employeesList = state.employeesList.map((employeesList: any) =>
        employeesList.id === action.payload.id
          ? {...employeesList, ...action.payload}
          : employeesList
      );
    });
    builder.addCase(updateEmployee.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(deleteEmployee.fulfilled, (state: any, action: any) => {
      state.employeesList = state.employeesList.filter(
        (employeesList: any) => employeesList.id.toString() !== action.payload.toString()
      );
    });
    builder.addCase(deleteEmployee.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
  }
});

export const selectDataList = (state: RootState) => state.employees;

export default EmployeesSlice.reducer;
