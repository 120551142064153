import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import { Link } from 'react-router-dom';
import TableContainer from 'Common/TableContainer';
import Select from 'react-select';

// Icons
import { CheckCircle, Eye, Pencil, Plus, Search, Trash2, X } from 'lucide-react';
import Modal from 'Common/Components/Modal';
import DeleteModal from 'Common/DeleteModal';

// Images
// react-redux
import { useSelector } from 'react-redux';

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  addPermissionList as onAddPermissionList,
  deletePermissionList as onDeletePermissionList,
  getPermissionList as onGetPermissionList,
  updatePermissionList as onUpdatePermissionList
} from '../slices/thunk';
import { ToastContainer } from 'react-toastify';
import { useAppDispatch } from "../../../../Common/hooks/redux";
import { selectDataList } from "../slices/reducer";
import WpLoader from "../../../../Common/Components/loader";

const PermissionList = () => {
  const dispatch = useAppDispatch();
  const {permissionList} = useSelector(selectDataList);

  const [eventData, setEventData] = useState<any>();

  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isViewOnly, setIsViewOnly] = useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(true)

  // Get Data
  useEffect(() => {
    setIsLoading(true);
    dispatch(onGetPermissionList({})).finally(() => setIsLoading(false));
  }, [dispatch]);

  // Delete Modal
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);

  // Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
    if (cell.id) {
      setEventData(cell);
    }
  };

  const handleDelete = () => {
    if (eventData) {
      dispatch(onDeletePermissionList(eventData.id));
      setDeleteModal(false);
    }
  };

  // Update Data
  const handleUpdateDataClick = (ele: any) => {
    setEventData({...ele});
    setIsEdit(true);
    setShow(true);
  };


  const handleViewDataClick = (ele: any) => {
    setEventData({...ele});
    setIsEdit(true);
    setIsViewOnly(true);
    setShow(true);
  };

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      tenantId: (eventData?.tenantId) ?? '',
      moduleName: (eventData?.name) ?? '',
      name: (eventData?.name) ?? '',
      description: (eventData?.description) ?? '',
    },
    validationSchema: Yup.object({
      tenantId: Yup.string().required("Please Enter TenantId"),
      moduleName: Yup.string().required("Please Enter Module Name"),
      name: Yup.string().required("Please Enter Name"),
      description: Yup.string().required("Please Enter Description"),
    }),

    onSubmit: (values) => {
      if (isEdit) {
        const updatePermission = {
          id: eventData ? eventData.id : 0,
          ...values,
        };
        // update Permission
        dispatch(onUpdatePermissionList(updatePermission));
      } else {
        const newPermission = {
          ...values,
          id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          tenantId: "#TW15000" + (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
        };
        // save new Permission
        dispatch(onAddPermissionList(newPermission));
      }
      toggle();
    },
  });


  //
  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData("");
      setIsEdit(false);
    } else {
      setShow(true);
      setEventData("");
      validation.resetForm();
    }
  }, [show, validation]);

  // Search Data
  const filterSearchData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = ['name', 'status'];
    // filterDataBySearch(permissionList, search, keysToSearch, setPermission);
  };

  // columns
  const Status = ({item}: any) => {
    if (item) {
      return (<span
          className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status">
                <CheckCircle className="size-3 mr-1.5"/>YES</span>
      );
    }

    return (<span
        className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent status">
            <X className="size-3 mr-1.5"/>NO</span>
    );

  };

  function retrieveData() {
    return permissionList && permissionList.length > 0 ?
      <TableContainer
        isPagination={true}
        columns={(columns || [])}
        data={(permissionList || [])}
        customPageSize={10}
        divclassName="-mx-5 -mb-5 overflow-x-auto"
        tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
        theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
        thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
        tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
        PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
      />
      :
      (
        <div className="noresult">
          <div className="py-6 text-center">
            <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20"/>
            <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
            <p className="mb-0 text-slate-500 dark:text-zink-200">
              No Data Found.
            </p>
          </div>
        </div>
      )
  }

  const columns = useMemo(() => [
      {
        header: (
          <div className="flex items-center h-full">
            <input id="CheckboxAll"
                   className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
                   type="checkbox"/>
          </div>
        ),
        enableSorting: false,
        id: "checkAll",
        cell: (cell: any) => {
          return (
            <div className="flex items-center h-full">
              <input id="Checkbox1"
                     className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
                     type="checkbox"/>
            </div>
          );
        }
      },
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: false
      },
      {
        header: "Module",
        accessorKey: "moduleName",
        enableColumnFilter: true,
        enableSorting: false
      },
      {
        header: "Is Active",
        accessorKey: "active",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <Status item={cell.getValue()}/>
        ),
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <div className="flex gap-3">
            <Link data-modal-target="addUserModal"
                  className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
                  to="#!"
                  onClick={() => {
                    const data = cell.row.original;
                    handleViewDataClick(data);
                  }}>
              <Eye className="inline-block size-4"/>
            </Link>
            <Link data-modal-target="addUserModal"
                  className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
                  to="#!"
                  onClick={() => {
                    const data = cell.row.original;
                    handleUpdateDataClick(data);
                  }}>
              <Pencil className="text-blue-700 inline-block size-3 ltr:mr-1 rtl:ml-1"/>
            </Link>
            <Link
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
              to="#!" onClick={() => {
              const orderData = cell.row.original;
              onClickDelete(orderData);
            }}>
              <Trash2 className="text-red-400 inline-block size-3 ltr:mr-1 rtl:ml-1"/>
            </Link>
          </div>
        ),
      }
    ], []
  );

  const options = [
    {value: 'Select Status', label: 'Select Status'},
    {value: true, label: 'Active'},
    {value: false, label: 'In Active'},
  ];

  const handleChange = (selectedOption: any) => {
    if (selectedOption.value === 'Select Status' || selectedOption.value === 'Hidden') {
      // setPermission(permissionList);
    } else {
      const filteredPermissions = permissionList.filter((data: any) => data.status === selectedOption.value);
      // setPermission(filteredPermissions);
    }
    // TODO Add implementation for fetching using the redux
  };

  return (
    <React.Fragment>
      <BreadCrumb title='Permissions List' pageTitle='Permissions'/>
      <DeleteModal show={deleteModal} onHide={deleteToggle} onDelete={handleDelete}/>
      <ToastContainer closeButton={false} limit={1}/>
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="permissionsTable">
            <div className="card-body">
              <div className="flex items-center">
                <h6 className="text-15 grow">Permissions</h6>
                <div className="shrink-0">
                  <button type="button"
                          className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                          onClick={toggle}><Plus className="inline-block size-4"/> <span
                    className="align-middle">Add Permission</span></button>
                </div>
              </div>
            </div>
            <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
              <form action="#!">
                <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                  <div className="relative xl:col-span-2">
                    <input type="text"
                           className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                           placeholder="Search for name, email, phone number etc..."
                           autoComplete="off" onChange={(e) => filterSearchData(e)}/>
                    <Search
                      className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600"/>
                  </div>
                  <div className="xl:col-span-2">
                    <Select
                      className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      options={options}
                      isSearchable={false}
                      defaultValue={options[0]}
                      onChange={(event: any) => handleChange(event)}
                      id="choices-single-default"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="card-body">
              {isLoading ? <WpLoader/> : retrieveData()}
            </div>
          </div>
        </div>
      </div>

      {/* Permission Modal  */}
      <Modal show={show} onHide={toggle} id="defaultModal" modal-center="true"
             className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
             dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600">
        <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                      closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
          <Modal.Title className="text-16">{isEdit ? "Edit Permission" : "Add Permission"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form action="#!" onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}>

            {isViewOnly && <div className="mb-3">
              <label htmlFor="tenantId" className="inline-block mb-2 text-base font-medium">
                Tenant ID
              </label>
              <input type="text" id="tenantId"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     disabled
              />
            </div>}
            <div className="mb-3">
              <label htmlFor="moduleName"
                     className="inline-block mb-2 text-base font-medium">Module Name</label>
              <input type="text" id="moduleName"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Enter module name"
                     name="designation"
                     readOnly={isViewOnly}
                     onChange={validation.handleChange}
                     value={validation.values.designation || ""}
              />
              {validation.touched.designation && validation.errors.designation ? (
                <p className="text-red-400">{validation.errors.designation}</p>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="name"
                     className="inline-block mb-2 text-base font-medium">Name</label>
              <input type="text" id="name"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Enter name"
                     name="name"
                     readOnly={isViewOnly}
                     onChange={validation.handleChange}
                     value={validation.values.name || ""}
              />
              {validation.touched.name && validation.errors.name ? (
                <p className="text-red-400">{validation.errors.name}</p>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="description"
                     className="inline-block mb-2 text-base font-medium">Description</label>
              <input type="text" id="description"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Enter designation"
                     name="designation"
                     readOnly={isViewOnly}
                     onChange={validation.handleChange}
                     value={validation.values.designation || ""}
              />
              {validation.touched.designation && validation.errors.designation ? (
                <p className="text-red-400">{validation.errors.designation}</p>
              ) : null}
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button type="reset" data-modal-close="addDocuments"
                      className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                      onClick={toggle}>Cancel
              </button>
              <button type="submit"
                      className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                {isEdit ? "Update Permission" : "Add Permission"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default PermissionList;
