import React, { useEffect, useState } from "react";
import AccountInfo from "./account_info";
import { Nav } from "Common/Components/Tab/Nav";
import Tab from "Common/Components/Tab/Tab";
import Overview from "./overview";
import Documents from "./documents";
import Projects from "./projects";
import Beneficiaries from "./beneficiaries";
import Contracts from "./contracts";
import WorkHistories from "./work_histories";
import Educations from "./educations";
import Referees from "./referees";
import Allocations from "./allocations";
import { Employee } from "../types";
import { useParams } from "react-router-dom";
import { Search } from "lucide-react";
import WpLoader from "../../../../Common/Components/loader";
import { getSingleEmployee } from "../slices/thunk";
import { useAppDispatch } from "../../../../Common/hooks/redux";

interface EmployeeAccountProps {
}

const EmployeeAccount: React.FC<EmployeeAccountProps> = () => {
  const {id} = useParams<{ id: string }>(); // Capture the ID from the URL

  console.log("ID: ", id)
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const [employeeInfo, setEmployeeInfo] =
    useState<Employee | {}>({});


  useEffect(() => {
    setIsLoading(true);
    if (id) {
      setIsLoading(true);
      dispatch(getSingleEmployee(id)).then((emp) => {
        setEmployeeInfo(emp.payload);
      }).finally(() => setIsLoading(false));
    }
  }, [dispatch, id]);


  document.title = "Account | WatuPro - Simplified HR Solution";

  function renderEmployeeDetails() {
    console.log("Employee details : ", employeeInfo);

    return employeeInfo ? (
      <Tab.Content className="tab-content">
        <Tab.Pane eventKey="overviewTabs" id="overviewTabs">
          <Overview employee={employeeInfo}/>
        </Tab.Pane>
        <Tab.Pane eventKey="documentsTabs" id="documentsTabs">
          <Documents employee={employeeInfo}/>
        </Tab.Pane>
        <Tab.Pane eventKey="beneficiariesTabs" id="beneficiariesTabs">
          <Beneficiaries employee={employeeInfo}/>
        </Tab.Pane>
        <Tab.Pane eventKey="contractsTabs" id="contractsTabs">
          <Contracts employee={employeeInfo}/>
        </Tab.Pane>
        <Tab.Pane eventKey="workHistoryTabs" id="workHistoryTabs">
          <WorkHistories employee={employeeInfo}/>
        </Tab.Pane>
        <Tab.Pane eventKey="educationTabs" id="educationTabs">
          <Educations employee={employeeInfo}/>
        </Tab.Pane>
        <Tab.Pane eventKey="refereeTabs" id="refereeTabs">
          <Referees employee={employeeInfo}/>
        </Tab.Pane>
        <Tab.Pane eventKey="allocationTabs" id="allocationTabs">
          <Allocations employee={employeeInfo}/>
        </Tab.Pane>
        <Tab.Pane eventKey="projectsTabs" id="projectsTabs">
          <Projects employee={employeeInfo}/>
        </Tab.Pane>
      </Tab.Content>
    ) : (
      <div className="noresult">
        <div className="py-6 text-center">
          <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20"/>
          <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
          <p className="mb-0 text-slate-500 dark:text-zink-200">
            No Data Found.
          </p>
        </div>
      </div>
    );
  }

  return (
    <Tab.Container defaultActiveKey="overviewTabs">
      <div className="mt-1 -ml-3 -mr-3 rounded-none card">
        <AccountInfo className="card-body !px-2.5" employee={employeeInfo}/>
        <div className="card-body !px-2.5 !py-0">
          <Nav className="flex flex-wrap w-full text-sm font-medium text-center nav-tabs">
            <Nav.Item eventKey="overviewTabs" className="group">
              <a href="#!" data-tab-toggle="" data-target="overviewTabs"
                 className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]">Overview</a>
            </Nav.Item>
            <Nav.Item eventKey="documentsTabs" className="group">
              <a href="#!" data-tab-toggle="" data-target="documentsTabs"
                 className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]">Documents</a>
            </Nav.Item>
            <Nav.Item eventKey="beneficiariesTabs" className="group">
              <a href="#!" data-tab-toggle="" data-target="beneficiariesTabs"
                 className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]">Beneficiaries</a>
            </Nav.Item>
            <Nav.Item eventKey="contractsTabs" className="group">
              <a href="#!" data-tab-toggle="" data-target="contractsTabs"
                 className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]">Contracts</a>
            </Nav.Item>
            <Nav.Item eventKey="workHistoryTabs" className="group">
              <a href="#!" data-tab-toggle="" data-target="workHistoryTabs"
                 className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]">Work
                History</a>
            </Nav.Item>
            <Nav.Item eventKey="educationTabs" className="group">
              <a href="#!" data-tab-toggle="" data-target="educationTabs"
                 className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]">
                Education
              </a>
            </Nav.Item>
            <Nav.Item eventKey="refereeTabs" className="group">
              <a href="#!" data-tab-toggle="" data-target="refereeTabs"
                 className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]">
                Referees
              </a>
            </Nav.Item>
            <Nav.Item eventKey="allocationTabs" className="group">
              <a href="#!" data-tab-toggle="" data-target="allocationTabs"
                 className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]">
                Benefits/Allocation
              </a>
            </Nav.Item>
            <Nav.Item eventKey="projectsTabs" className="group">
              <a href="#!" data-tab-toggle="" data-target="projectsTabs"
                 className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]">Projects</a>
            </Nav.Item>
          </Nav>
        </div>
      </div>
      {isLoading
        ? <WpLoader/>
        : renderEmployeeDetails()
      }
    </Tab.Container>
  );
}

export default EmployeeAccount;