import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    getAttendance as getAttendanceApi,
    getMainAttendance as getMainAttendanceApi
} from "../../../helpers/backend_helper";
import { GetAttendancePayload } from "../../../pages/HRManagement/types";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const getAttendance = createAsyncThunk<
    any, GetAttendancePayload, { rejectValue: any }
>("hrManagement/getAttendance", async (payload, {rejectWithValue}) => {
    try {
        const response = await getAttendanceApi(payload);
        toast.success("Attendance retrieved successfully", {autoClose: 2000});
        return response;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getMainAttendance = createAsyncThunk<
    any, GetAttendancePayload, { rejectValue: any }
>("hrManagement/getMainAttendance", async (payload, {rejectWithValue}) => {
    try {
        const response = await getMainAttendanceApi(payload);
        toast.success("Main attendance retrieved successfully", {autoClose: 2000});
        return response;
    } catch (error) {
        return rejectWithValue(error);
    }
});
