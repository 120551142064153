const ProjectsData = [
    {
        id: "62d7168a-2cec-4609-8b87-93765234324a",
        tenantId: "284c8c42-9773-4009-af8b-6ee1f5183c8a",
        officeId: null,
        name: "Test Project 2",
        description: "Test Project 2 Description",
        startDate: null,
        endDate: null,
        address: null,
        createdBy: "admin.admin2",
        createdDate: "2024-06-17T10:05:45.347+00:00",
        lastModifiedBy: "admin.admin2",
        lastModifiedDate: "2024-06-17T10:05:45.347+00:00",
    },
    {
        id: "e931480c-5845-4b46-aa1a-47225447f777",
        tenantId: "284c8c42-9773-4009-af8b-6ee1f5183c8a",
        officeId: null,
        name: "Test Project 1",
        description: "Test Project Description",
        startDate: null,
        endDate: null,
        address: null,
        createdBy: "admin.admin2",
        createdDate: "2024-06-17T10:03:21.822+00:00",
        lastModifiedBy: "admin.admin2",
        lastModifiedDate: "2024-06-17T10:03:21.823+00:00",
    }
];

export { ProjectsData };
