import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthData, AuthResponse, LogoutResponse } from "../../../pages/types";
import { RootState } from "../../index";

interface LoginState {
    user?: AuthData;
    error: string;
    success: boolean;
    isUserLogout: boolean;
}

const initialState: LoginState = {
    user: undefined,
    error: "",
    success: false,
    isUserLogout: false
};

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        loginSuccess(state: LoginState, action: PayloadAction<AuthResponse>) {
            console.log(state)
            console.log(action.payload)
            state.user = action.payload.body.user;
            state.success = true;
        },
        loginError(state: LoginState, action: PayloadAction<string | any>) {
            state.error = action.payload;
            state.success = false;
        },
        logoutSuccess(state: LoginState, action: PayloadAction<LogoutResponse>) {
            localStorage.removeItem("authUser");

            state.isUserLogout = action.payload.respCode === 600;
        }
    },
});

export const {loginSuccess, loginError, logoutSuccess} = loginSlice.actions;

export const selectAuthDetails = (state: RootState) => state.auth;

export default loginSlice.reducer;
