import React from "react";
import PermissionList from "../pages";
import AddUpdatePermission from "../pages/add_update";

interface RouteObject {
  path: string;
  component: React.ComponentType<any>;
  exact?: boolean;
}

const permissionRoutes: Array<RouteObject> = [
  {path: '/apps-permissions-list', component: PermissionList},
  {path: '/apps-permissions-list/add', component: AddUpdatePermission},
  {path: '/apps-permissions-list/update/:id', component: AddUpdatePermission},
];

export default permissionRoutes;
