import AddUpdateEmployee from "../pages/add_update";
import React from "react";
import EmployeeList from "../pages";
import EmployeeAccount from "../components";

interface RouteObject {
  path: string;
  component: React.ComponentType<any>;
  exact?: boolean;
}

const employeeRoutes: Array<RouteObject> = [
  {path: '/apps-employees-list', component: EmployeeList},
  {path: '/apps-employees-list/add', component: AddUpdateEmployee},
  {path: '/apps-employees-list/update/:id', component: AddUpdateEmployee},
  {path: "/apps-employees-list/:id", component: EmployeeAccount},
];

export default employeeRoutes;
