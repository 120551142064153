import { createSlice } from "@reduxjs/toolkit";
import {
    addPermissionList,
    deletePermissionList,
    getPermissionList,
    getSinglePermission,
    updatePermissionList
} from './thunk';
import { RootState } from "../../../../slices";

export const initialState = {
  permissionList: [],
  errors: {}
};

const PermissionsSlice = createSlice({
  name: 'Permissions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPermissionList.fulfilled, (state: any, action: any) => {
      state.permissionList = action.payload;
    });
    builder.addCase(getPermissionList.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getSinglePermission.fulfilled, (state: any, action: any) => {
      state.tenantList = action.payload;
    });
    builder.addCase(getSinglePermission.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(addPermissionList.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(addPermissionList.fulfilled, (state: any, action: any) => {
      state.permissionList = state.permissionList.map((list: any) =>
        list.id === action.payload.id
          ? {...list, ...action.payload}
          : list
      );
    });
    builder.addCase(updatePermissionList.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(updatePermissionList.fulfilled, (state: any, action: any) => {
      state.permissionList = state.permissionList.filter(
        (permissionList: any) => permissionList.id.toString() !== action.payload.toString()
      );
    });
    builder.addCase(deletePermissionList.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });


  }
});

export const selectDataList = (state: RootState) => state.permissions;

export default PermissionsSlice.reducer;