import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addEmployee as addEmployeeApi,
  deleteEmployee as deleteEmployeeApi,
  getEmployee as getEmployeeApi,
  getSingleEmployee as getSingleEmployeeApi,
  updateEmployee as updateEmployeeApi,
} from "../../../../helpers/backend_helper";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { GetEmployeePayload } from "../types";

export const getEmployee = createAsyncThunk<
  any,
  GetEmployeePayload,
  { rejectValue: any }
>(
  "hrManagement/getEmployee", async (payload, {rejectWithValue}) => {
    try {
      const response = await getEmployeeApi(payload);

      console.log("Employee Response: ", response)

      return response;
    } catch (error: any) {
      const err: AxiosError<any> = error;

      if (!err.response) {
        return rejectWithValue(err);
      }

      return rejectWithValue(err.response.data);
    }
  });

export const getSingleEmployee = createAsyncThunk("hrManagement/getSingleEmployee", async (id: string) => {
  try {
    const response = await getSingleEmployeeApi(id);

    console.log("Single Employee Response: ", response)

    return response;
  } catch (error: any) {
    const err: AxiosError<any> = error;

    if (!err.response) {
      return err
    }

    return err;
  }
});

export const addEmployee = createAsyncThunk("hrManagement/addEmployee", async (event: any) => {
  try {
    const response = await addEmployeeApi(event);
    toast.success("Employee Added Successfully", {autoClose: 2000});
    console.log("Results returned: ", response)
    return response;
  } catch (error) {
    toast.error("Employee Added Failed", {autoClose: 2000});
    return error;
  }
});
export const updateEmployee = createAsyncThunk("hrManagement/updateEmployee", async (event: any) => {
  try {
    const response = await updateEmployeeApi(event);
    toast.success("Employee updated Successfully", {autoClose: 2000});
    return response;
  } catch (error) {
    toast.error("Employee updated Failed", {autoClose: 2000});
    return error;
  }
});
export const deleteEmployee = createAsyncThunk("hrManagement/deleteEmployee", async (event: any) => {
  try {
    const response = await deleteEmployeeApi(event);
    toast.success("Employee deleted Successfully", {autoClose: 2000});
    return response;
  } catch (error) {
    toast.error("Employee deleted Failed", {autoClose: 2000});
    return error;
  }
});
