import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    addTenantList as addTenantListApi,
    deleteTenantList as deleteTenantListApi,
    getTenantList as getTenantListApi,
    updateTenantList as updateTenantListApi,
} from "../../helpers/backend_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { GetTenantPayload } from "../../pages/Configurations/Tenants/types/tenant";

export const getTenantList = createAsyncThunk<
    any,
    GetTenantPayload,
    { rejectValue: any }
>(
    "tenants/getTenantList", async (payload, {rejectWithValue}) => {
        try {
            const response = await getTenantListApi(payload);

            console.log("Response: ", response)

            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    });
export const addTenantList = createAsyncThunk("tenants/addTenantList", async (event: any) => {
    try {
        const response = addTenantListApi(event);
        const data = await response;
        toast.success("Tenant Added Successfully", {autoClose: 2000});
        return data;
    } catch (error) {
        toast.error("Tenant Added Failed", {autoClose: 2000});
        return error;
    }
});
export const updateTenantList = createAsyncThunk("tenants/updateTenantList", async (event: any) => {
    try {
        const response = updateTenantListApi(event);
        const data = await response;
        toast.success("Tenant updated Successfully", {autoClose: 2000});
        return data;
    } catch (error) {
        toast.error("Tenant updated Failed", {autoClose: 2000});
        return error;
    }
});
export const deleteTenantList = createAsyncThunk("tenants/deleteTenantList", async (event: any) => {
    try {
        const response = deleteTenantListApi(event);
        toast.success("Tenant deleted Successfully", {autoClose: 2000});
        return response;
    } catch (error) {
        toast.error("Tenant deleted Failed", {autoClose: 2000});
        return error;
    }
});
