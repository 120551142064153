import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    addWorkflowList as addWorkflowListApi,
    deleteWorkflowList as deleteWorkflowListApi,
    getWorkflowList as getWorkflowListApi,
    updateWorkflowList as updateWorkflowListApi,
} from "../../helpers/backend_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const getWorkflowList = createAsyncThunk("tenants/getWorkflowList", async () => {
    try {
        const response = getWorkflowListApi();
        console.log('Workflow response: ', response)
        return response;
    } catch (error) {
        return error;
    }
});
export const addWorkflowList = createAsyncThunk("tenants/addWorkflowList", async (event: any) => {
    try {
        const response = addWorkflowListApi(event);
        const data = await response;
        toast.success("Workflow Added Successfully", {autoClose: 2000});
        return data;
    } catch (error) {
        toast.error("Workflow Added Failed", {autoClose: 2000});
        return error;
    }
});
export const updateWorkflowList = createAsyncThunk("tenants/updateWorkflowList", async (event: any) => {
    try {
        const response = updateWorkflowListApi(event);
        const data = await response;
        toast.success("Workflow updated Successfully", {autoClose: 2000});
        return data;
    } catch (error) {
        toast.error("Workflow updated Failed", {autoClose: 2000});
        return error;
    }
});
export const deleteWorkflowList = createAsyncThunk("tenants/deleteWorkflowList", async (event: any) => {
    try {
        const response = deleteWorkflowListApi(event);
        toast.success("Workflow deleted Successfully", {autoClose: 2000});
        return response;
    } catch (error) {
        toast.error("Workflow deleted Failed", {autoClose: 2000});
        return error;
    }
});
