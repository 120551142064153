import React, { useCallback, useEffect, useState } from 'react';
import Modal from "../../../../Common/Components/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import useReduxPost from "../../../../Common/hooks/redux-post";
import { useReduxFetch } from "../../../../Common/hooks";
import { addEmployee, getEmployee, getSingleEmployee, updateEmployee } from "../slices/thunk";
import { Tenant } from "../../tenant/types";
import { getTenantList } from "../../tenant/slices/thunk";
import { GenericDataList } from "../../../../Common/types";
import Select from "react-select";
import {
  addEmployee as onAddEmployee,
  updateEmployee as onUpdateEmployee
} from "../../../../slices/hrManagement/employees/thunk";
import { useAppDispatch } from "../../../../Common/hooks/redux";
import { Employee } from "../types";
import { gender, maritalStatuses, titles } from "../db";
import { getLoggedUser } from "../../../../helpers/api_helper";

interface AddUpdateProps {
  id?: string
}

/**
 * @function
 * @name AddUpdateEmployee
 * @description add/update
 * @version 1.0.0
 * @since 1.0.0
 * @author Muhammad Mwinchande <ammwinchande@gmail.com>
 * @returns {object}
 */
const AddUpdateEmployee: React.FC<AddUpdateProps> = ({id}) => {
  const navigate = useNavigate();
  const reduxPost = useReduxPost();
  const reduxFetch = useReduxFetch();
  const dispatch = useAppDispatch();

  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isViewOnly, setIsViewOnly] = useState<boolean>(false);
  const [eventData, setEventData] = useState<Employee>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [tenants, setTenants] = useState<GenericDataList[]>([]);
  const [tenantId, setTenantId] = useState<any>([])


  useEffect(() => {
    if (id) {
      console.log("ID Supplied: ", id)
      setIsLoading(true);
      reduxFetch(getSingleEmployee, id)
        .then((data: any) => {
          console.log("Response returned: ", data)
          validation.values(data)
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err?.errors) {
            validation.errors(err?.errors);
          }
        });
    }
  }, [id, reduxFetch]);

  useEffect(() => {
    setIsLoading(true);
    reduxFetch(getTenantList)
      .then((data: Tenant[]) => {
        let tenantsList = data.map((tenant) => {
          return {
            id: tenant.id,
            name: tenant.name,
          };
        });
        // @ts-ignore
        setTenants(tenantsList);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, []);

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: (eventData?.title) ?? '',
      firstName: (eventData?.firstName) ?? '',
      otherNames: (eventData?.otherNames) ?? '',
      sirName: (eventData?.sirName) ?? '',
      gender: (eventData?.gender) ?? '',
      maritalStatus: (eventData?.maritalStatus) ?? '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
      firstName: Yup.string().required("Please Enter First Name"),
      sirName: Yup.string().required("Please Enter Sir Name"),
      otherNames: Yup.string().optional(),
      gender: Yup.string().required("Please Enter Gender"),
      maritalStatus: Yup.string().optional(),
    }),
    onSubmit: (values: any, {resetForm}) => {

      console.log("Values onSubmit: ", values);

      if (isEdit) {
        const updateData = {
          id: eventData ? eventData.id : 0,
          ...values,
        };
        // update user
        dispatch(onUpdateEmployee(updateData));
      } else {
        // save new user
        dispatch(onAddEmployee(values));
      }
      toggle();
    },
  });

  const handleCancel = () => {
    navigate("..");
  };

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData(undefined);
      setIsEdit(false);
      setIsViewOnly(false);
    } else {
      setShow(true);
      setEventData(undefined);
      validation.resetForm();
    }
  }, [show, validation]);

  const put = (values: any) => {
    setIsLoading(true);
    reduxPost(updateEmployee, values)
      .then(() => {
        setIsLoading(false);
        reduxFetch(getEmployee);
        handleCancel();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const post = (values: any) => {
    setIsLoading(true);
    reduxPost(addEmployee, values)
      .then(() => {
        setIsLoading(false);
        reduxFetch(getEmployee);
        validation.values.reset()
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleChange = (selectedOption: any) => {
    if (selectedOption.value === 'Select Tenant' || selectedOption.value === 'Hidden') {
      setTenantId(tenants);
    } else {
      const filteredTenants = tenants.filter((data: any) => data.status === selectedOption.value);
      setTenantId(filteredTenants);
    }
  };

  function handleSubmit() {
    return (e: any) => {
      e.preventDefault();
      validation.handleSubmit();
      const values = {
        ...validation.values,
        tenantId: getLoggedUser().tenatidId,
      }

      if (isEdit) {
        const updateData = {
          id: eventData?.id!,
          ...values,
        };
        // update user
        dispatch(onUpdateEmployee(updateData));
      } else {
        // save new user
        dispatch(onAddEmployee(values));
      }
      toggle();
    };
  }


  return (
    <Modal show={true} onHide={toggle} modal-center="true"
           className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
           dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600">
      <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-500"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
        <Modal.Title className="text-16">
          {isEdit ? "Edit Employee" : "Add Employee"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
        <form className="create-form" id="create-form"
              onSubmit={handleSubmit()}
        >
          <input type="hidden" value="" name="id" id="id"/>
          <input type="hidden" value="add" name="action" id="action"/>
          <input type="hidden" id="id-field"/>
          <div id="alert-error-msg"
               className="hidden px-4 py-3 text-sm text-red-500 border border-transparent rounded-md bg-red-50 dark:bg-red-500/20">
          </div>
          <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
            <div className="xl:col-span-12">
              <label htmlFor="titleSelect"
                     className="inline-block mb-2 text-base font-medium">
                Title
              </label>
              <Select
                options={titles}
                id="titleSelect"
                name="title"
                onChange={(selectedOption: any) => {
                  validation.handleChange({
                    target: {
                      name: "maritalStatus",
                      value: selectedOption.value,
                    },
                  });
                }}
              />
              {validation.touched.title && validation.errors.title ? (
                <p className="text-red-400">{validation.errors.title}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="employeeFirstNameInput"
                     className="inline-block mb-2 text-base font-medium">First Name</label>
              <input type="text" id="employeeFirstNameInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="First name"
                     name="firstName"
                     onChange={validation.handleChange}
                     value={validation.values.firstName || ""}
              />
              {validation.touched.firstName && validation.errors.firstName ? (
                <p className="text-red-400">{validation.errors.firstName}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="employeeSirNameInput"
                     className="inline-block mb-2 text-base font-medium">Sir Name</label>
              <input type="text" id="employeeSirNameInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Sir name"
                     name="sirName"
                     onChange={validation.handleChange}
                     value={validation.values.sirName || ""}
              />
              {validation.touched.sirName && validation.errors.sirName ? (
                <p className="text-red-400">{validation.errors.sirName}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="employeeOtherNameInput"
                     className="inline-block mb-2 text-base font-medium">Other Names</label>
              <input type="text" id="employeeOtherNameInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Other name"
                     name="otherNames"
                     onChange={validation.handleChange}
                     value={validation.values.otherNames || ""}
              />
              {validation.touched.otherNames && validation.errors.otherNames ? (
                <p className="text-red-400">{validation.errors.otherNames}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="genderSelect"
                     className="inline-block mb-2 text-base font-medium">Gender</label>
              <Select
                options={gender}
                id="genderSelect"
                name="gender"
                onChange={(selectedOption: any) => {
                  validation.handleChange({
                    target: {
                      name: "gender",
                      value: selectedOption.value,
                    },
                  });
                }}
              />
              {validation.touched.gender && validation.errors.gender ? (
                <p className="text-red-400">{validation.errors.gender}</p>
              ) : null}
            </div>
            <div className="xl:col-span-12">
              <label htmlFor="maritalStatusSelect"
                     className="inline-block mb-2 text-base font-medium">
                Marital Status
              </label>
              <Select
                options={maritalStatuses}
                id="maritalStatusSelect"
                name="maritalStatus"
                onChange={(selectedOption: any) => {
                  validation.handleChange({
                    target: {
                      name: "maritalStatus",
                      value: selectedOption.value,
                    },
                  });
                }}
              />
              {validation.touched.maritalStatus && validation.errors.maritalStatus ? (
                <p className="text-red-400">{validation.errors.maritalStatus}</p>
              ) : null}
            </div>

          </div>
          <div className="flex justify-end gap-2 mt-4">
            <button type="reset" id="close-modal" data-modal-close="addEmployeeModal"
                    className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                    onClick={toggle}>Cancel
            </button>
            <button type="submit" id="addNew"
                    className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
              {isEdit ? "Update" : "Add Employee"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddUpdateEmployee;
