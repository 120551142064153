import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../slices";
import { addProject, deleteProject, getProjectList, updateProject } from "./thunk";

export const initialState = {
  projectList: [],
  errors: {}
};

const ProjectsSlice = createSlice({
  name: 'Projects',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProjectList.fulfilled, (state: any, action: any) => {
      state.projectList = action.payload;
    });
    builder.addCase(getProjectList.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(addProject.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(addProject.fulfilled, (state: any, action: any) => {
      state.projectList = state.projectList.map((list: any) =>
        list.id === action.payload.id
          ? {...list, ...action.payload}
          : list
      );
    });
    builder.addCase(updateProject.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(updateProject.fulfilled, (state: any, action: any) => {
      state.projectList = state.projectList.filter(
        (projectList: any) => projectList.id.toString() !== action.payload.toString()
      );
    });
    builder.addCase(deleteProject.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });


  }
});

export const selectDataList = (state: RootState) => state.projects;

export default ProjectsSlice.reducer;
