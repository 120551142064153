export const VIEW_ROLE_LIST = "";
export const VIEW_ROLE = "";
export const UPDATE_ROLE_LIST = "";
export const DELETE_ROLE_LIST = "";
export const APPROVE_ROLE_LIST = "";

export const RoleStatuses = [
  {value: '', label: 'Select Status'},
  {value: 'active', label: 'Active'},
  {value: 'inactive', label: 'In Active'},
];
