import { APIClient } from "./api_helper";

import * as url from "./url_helper";
import {
    Employee,
    GetAttendancePayload,
    GetEmployeeLeavePayload,
    GetEmployeePayload
} from "../pages/HRManagement/types";
import { GetProjectPayload } from "../app/modules/project/types/project";
import { GetUserPayload } from "../app/modules/user/types/user";
import { GetRolePayload } from "../app/modules/role/types/role";
import { GetPermissionPayload } from "../app/modules/permission/types/permission";
import { GetTenantPayload } from "../app/modules/tenant/types/tenant";

const api = new APIClient();

// HR Management
// Employee List
export const getEmployee = (payload: GetEmployeePayload) => api.get(url.GET_EMPLOYEE, payload);
export const getSingleEmployee = (id: string) => api.getSingle(url.GET_EMPLOYEE, id);
export const addEmployee = (data: Employee) => api.create(url.ADD_EMPLOYEE, data);
export const updateEmployee = (data: Employee) => api.update(url.UPDATE_EMPLOYEE, data);
export const deleteEmployee = (data: Employee) => api.delete(url.DELETE_EMPLOYEE, {headers: {data}});

// Holidays
export const getHolidays = () => api.get(url.GET_HOLIDAYS, null);
export const addHolidays = (data: any) => api.create(url.ADD_HOLIDAYS, data);
export const updateHolidays = (data: any) => api.update(url.UPDATE_HOLIDAYS, data);
export const deleteHolidays = (data: any) => api.delete(url.DELETE_HOLIDAYS, {headers: {data}});

// Leaves Manage

// Leave Manage (Employee)
export const getLeaveManageEmployee = (payload: GetEmployeeLeavePayload) => api.get(url.GET_LEAVE_MANAGE_EMPLOYEE, payload);

// Leave Manage (HR)
export const getLeaveManageHR = (payload: GetEmployeeLeavePayload) => api.get(url.GET_LEAVE_MANAGE_HR, payload);
export const addLeaveManageHR = (data: any) => api.create(url.ADD_LEAVE_MANAGE_HR, data);
export const updateLeaveManageHR = (data: any) => api.update(url.UPDATE_LEAVE_MANAGE_HR, data);
export const deleteLeaveManageHR = (data: any) => api.delete(url.DELETE_LEAVE_MANAGE_HR, {headers: {data}});

// Attendance
export const getAttendance = (payload: GetAttendancePayload) => api.get(url.GET_ATTENDANCE, payload);
export const getMainAttendance = (payload: GetAttendancePayload) => api.get(url.GET_MAIN_ATTENDANCE, payload);

// Offices
export const getDepartments = () => api.get(url.GET_DEPARTMENTS, null);
export const addDepartments = (data: any) => api.create(url.ADD_DEPARTMENTS, data);
export const updateDepartments = (data: any) => api.update(url.UPDATE_DEPARTMENTS, data);
export const deleteDepartments = (data: any) => api.delete(url.DELETE_DEPARTMENTS, {headers: {data}});

// Sales
// Estimates
export const getEstimates = () => api.get(url.GET_ESTIMATES, null);
export const addEstimates = (data: any) => api.create(url.ADD_ESTIMATES, data);
export const updateEstimates = (data: any) => api.update(url.UPDATE_ESTIMATES, data);
export const deleteEstimates = (data: any) => api.delete(url.DELETE_ESTIMATES, {headers: {data}});

// Payments
export const getPayments = () => api.get(url.GET_PAYMENTS, null);

// Expenses
export const getExpenses = () => api.get(url.GET_EXPENSES, null);
export const addExpenses = (data: any) => api.create(url.ADD_EXPENSES, data);
export const updateExpenses = (data: any) => api.update(url.UPDATE_EXPENSES, data);
export const deleteExpenses = (data: any) => api.delete(url.DELETE_EXPENSES, {headers: {data}});

// Payroll
// Employee Salary
export const getEmployeeSalary = () => api.get(url.GET_EMPLOYEE_SALARY, null);
export const addEmployeeSalary = (data: any) => api.create(url.ADD_EMPLOYEE_SALARY, data);
export const updateEmployeeSalary = (data: any) => api.update(url.UPDATE_EMPLOYEE_SALARY, data);
export const deleteEmployeeSalary = (data: any) => api.delete(url.DELETE_EMPLOYEE_SALARY, {headers: {data}});

// Users
// List View
export const getUserList = (payload: GetUserPayload) => api.get(url.GET_USER_LIST, payload);
export const getSingleUser = (id: string) => api.getSingle(url.GET_USER_LIST, id);
export const addUserList = (data: any) => api.create(url.ADD_USER_LIST, data);
export const updateUserList = (data: any) => api.update(url.UPDATE_USER_LIST, data);
export const deleteUserList = (user: any) => api.delete(url.DELETE_USER_LIST, {headers: {user}});

// Grid View
export const getUserGrid = () => api.get(url.GET_USER_GRID, null);
export const addUserGrid = (data: any) => api.create(url.ADD_USER_GRID, data);
export const updateUserGrid = (data: any) => api.update(url.UPDATE_USER_GRID, data);
export const deleteUserGrid = (user: any) => api.delete(url.DELETE_USER_GRID, {headers: {user}});

// Tenants
export const getTenantList = (payload: GetTenantPayload) => api.get(url.GET_TENANT_LIST, payload);
export const getSingleTenant = (id: string) => api.getSingle(url.GET_TENANT, id);
export const addTenantList = (data: any) => api.create(url.ADD_TENANT_LIST, data);
export const updateTenantList = (data: any) => api.update(url.UPDATE_TENANT_LIST, data);
export const deleteTenantList = (tenant: any) => api.delete(url.DELETE_TENANT_LIST, {headers: {tenant}});

// Offices
export const getOfficeList = () => api.get(url.GET_OFFICE_LIST, null);
export const getSingleOffice = (id: string) => api.getSingle(url.GET_OFFICE_LIST, id);
export const addOfficeList = (data: any) => api.create(url.ADD_OFFICE_LIST, data);
export const updateOfficeList = (data: any) => api.update(url.UPDATE_OFFICE_LIST, data);
export const deleteOfficeList = (office: any) => api.delete(url.DELETE_OFFICE_LIST, {headers: {office}});

// Roles
export const getRoleList = (payload: GetRolePayload) => api.get(url.GET_ROLE_LIST, payload);
export const getSingleRole = (id: string) => api.getSingle(url.GET_ROLE_LIST, id);
export const addRoleList = (data: any) => api.create(url.ADD_ROLE_LIST, data);
export const updateRoleList = (data: any) => api.update(url.UPDATE_ROLE_LIST, data);
export const deleteRoleList = (role: any) => api.delete(url.DELETE_ROLE_LIST, {headers: {role}});

// Permissions
export const getPermissionList = (payload: GetPermissionPayload) => api.get(url.GET_PERMISSION_LIST, payload);
export const getSinglePermission = (id: string) => api.getSingle(url.GET_PERMISSION_LIST, id);
export const addPermissionList = (data: any) => api.create(url.ADD_PERMISSION_LIST, data);
export const updatePermissionList = (data: any) => api.update(url.UPDATE_PERMISSION_LIST, data);
export const deletePermissionList = (permission: any) => api.delete(url.DELETE_PERMISSION_LIST, {headers: {permission}});

// Workflows
export const getWorkflowList = () => api.get(url.GET_WORKFLOW_LIST, null);
export const addWorkflowList = (data: any) => api.create(url.ADD_WORKFLOW_LIST, data);
export const updateWorkflowList = (data: any) => api.update(url.UPDATE_WORKFLOW_LIST, data);
export const deleteWorkflowList = (workflow: any) => api.delete(url.DELETE_WORKFLOW_LIST, {headers: {workflow}});

// Project
export const getProjectList = (payload: GetProjectPayload) => api.get(url.GET_PROJECT_LIST, payload);
export const getSingleProject = (id: string) => api.getSingle(url.GET_PROJECT_LIST, id);
export const addProjectList = (data: any) => api.create(url.ADD_PROJECT_LIST, data);
export const updateProjectList = (data: any) => api.update(url.UPDATE_PROJECT_LIST, data);
export const deleteProjectList = (project: any) => api.delete(url.DELETE_PROJECT_LIST, {headers: {project}});
