import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  addProjectList as addProjectListApi,
  deleteProjectList as deleteProjectListApi,
  getProjectList as getProjectListApi,
  getSingleProject as getSingleProjectApi,
  updateProjectList as updateProjectListApi,
} from "../../../../helpers/backend_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { AxiosError } from "axios";
import { GetProjectPayload } from "../types/project";

export const getProjectList = createAsyncThunk<
  any,
  GetProjectPayload,
  { rejectValue: any }
>("projects/getProjectList", async (payload, {rejectWithValue}) => {
  try {
    const response = await getProjectListApi(payload);
    console.log('Project response: ', response)
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const addProject = createAsyncThunk("projects/addProjectList", async (event: any) => {
  try {
    const response = await addProjectListApi(event);
    toast.success("Project Added Successfully", {autoClose: 2000});
    return response;
  } catch (error) {
    toast.error("Project Added Failed", {autoClose: 2000});
    return error;
  }
});
export const getSingleProject = createAsyncThunk("projects/getSingle", async (id: string) => {
  try {
    const response = await getSingleProjectApi(id);

    console.log("Single project Response: ", response)

    return response;
  } catch (error: any) {
    const err: AxiosError<any> = error;

    if (!err.response) {
      return err
    }

    return err;
  }
});
export const updateProject = createAsyncThunk("projects/updateProjectList", async (event: any) => {
  try {
    const response = await updateProjectListApi(event);
    toast.success("Project updated Successfully", {autoClose: 2000});
    return response;
  } catch (error) {
    toast.error("Project updated Failed", {autoClose: 2000});
    return error;
  }
});
export const deleteProject = createAsyncThunk("projects/deleteProjectList", async (event: any) => {
  try {
    const response = await deleteProjectListApi(event);
    toast.success("Project deleted Successfully", {autoClose: 2000});
    return response;
  } catch (error) {
    toast.error("Project deleted Failed", {autoClose: 2000});
    return error;
  }
});
