const InvoiceList = [
    {id: 1, invoiceId: "#TW15090251", name: "Paula Keenan", amount: "$873.96", date: "21 Jan, 2024", status: "Unpaid"},
    {
        id: 2,
        invoiceId: "#TW15090252",
        name: "Jaqueline Hammes",
        amount: "$1,203.74",
        date: "27 Oct, 2023",
        status: "Paid"
    },
    {id: 3, invoiceId: "#TW15090253", name: "Akeem Paucek", amount: "$1,423.10", date: "04 Feb, 2024", status: "Paid"},
    {
        id: 4,
        invoiceId: "#TW15090254",
        name: "Stephon Trantow",
        amount: "$1,420.99",
        date: "14 July, 2023",
        status: "Cancel"
    },
    {
        id: 5,
        invoiceId: "#TW15090255",
        name: "Domenic Tromp",
        amount: "$247.18",
        date: "11 April, 2023",
        status: "Refund"
    },
    {id: 6, invoiceId: "#TW15090256", name: "Marie Prohaska", amount: "$559.32", date: "03 Aug, 2023", status: "Paid"},
    {
        id: 7,
        invoiceId: "#TW15090257",
        name: "Ethel Corwin",
        amount: "$2,147.65",
        date: "15 Dec, 2024",
        status: "Unpaid"
    },
];

export { InvoiceList };
