const OfficesData = [
    {
        id: "bff5a3e3-0f88-4f5f-b020-b92f5bb752c7",
        tenantId: "6bb3b964-e376-40a9-9ce7-561879e64ec2",
        name: "Test Office1",
        address: null,
        projects: [],
        tenant: {
            id: "6bb3b964-e376-40a9-9ce7-561879e64ec2",
            name: "Test Tenant5",
            code: "T1005",
            parentId: "",
            superTenant: true,
            status: "pendingApproval",
            statusRemark: "",
            createdBy: null,
            createdDate: "2024-05-11T10:07:46.675+00:00",
            lastModifiedBy: null,
            lastModifiedDate: "2024-05-11T10:12:27.326+00:00",
            address: null,
            projects: null,
            offices: null,
            users: null
        },
        createdBy: null,
        createdDate: "2024-05-18T09:47:41.697+00:00",
        lastModifiedBy: null,
        lastModifiedDate: "2024-05-18T09:47:41.698+00:00",
    },
    {
        id: "9c8ea502-afc4-4f7a-951d-ff87a34fac12",
        tenantId: "6bb3b964-e376-40a9-9ce7-561879e64ec2",
        name: "Test Office2",
        address: null,
        projects: [],
        tenant: {
            id: "97341a6e-53e4-473f-8281-33e0ae30249c",
            name: "Test Tenant2",
            code: "T1002",
            parentId: "",
            superTenant: true,
            status: "active",
            statusRemark: "New Tenant Added",
            createdBy: null,
            createdDate: "2024-05-11T12:49:45.292+00:00",
            lastModifiedBy: null,
            lastModifiedDate: "2024-05-11T12:49:45.293+00:00",
            address: null,
            projects: null,
            offices: null,
            users: null
        },
        createdBy: "loggedInUser",
        createdDate: "2024-05-18T10:54:33.611+00:00",
        lastModifiedBy: "loggedInUser",
        lastModifiedDate: "2024-05-18T10:54:33.613+00:00",
    }
];

export { OfficesData };
