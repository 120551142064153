import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from "./redux";
import { toast } from "react-toastify";

/**
 * Hook for fetching data using redux
 * @version 2.0.0
 * @since 2.0.0
 * @author Muhammad Mwinchande
 * @returns Response from dispatch
 * @returns {Function} a get function to redux
 */
function ReduxFetch() {
    const dispatch = useAppDispatch();

    /**
     * Function for fetching the data using redux
     * @param action redux action
     * @param filter array of objects specifically for user management. Leave empty if not used in user management
     * @param canAlert
     * @version 2.0.0
     * @since 2.0.0
     * @author Muhammad Mwinchande
     * @returns Response from dispatch
     */
    const reduxFetch = async (action: any, filter: any = null, canAlert: boolean = true) => {
        try {
            let response;
            filter
                ? (response = await dispatch(action(filter)))
                : (response = await dispatch(action()));
            return Promise.resolve(unwrapResult(response));
        } catch (error: any) {
            console.log('the error', error);

            if (canAlert) {
                if (error.desc) {
                    toast.error('error', error.desc);
                } else if (error.message) {
                    toast.error('error', error.message);
                } else {
                    toast.error(
                        "Internal Server Error. Contact Admin if you are online"
                    );
                }
            }

            return Promise.reject(error);
        }
    };
    return reduxFetch;
}

export default ReduxFetch;
