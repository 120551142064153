import TenantList from "../pages";
import AddUpdateTenant from "../pages/add_update";
import React from "react";

interface RouteObject {
    path: string;
    component: React.ComponentType<any>;
    exact?: boolean;
}

const tenantRoutes: Array<RouteObject> = [
    {path: '/apps-tenants-list', component: TenantList},
    {path: '/apps-tenants-list/add', component: AddUpdateTenant},
    {path: '/apps-tenants-list/update/:id', component: AddUpdateTenant},
];

export default tenantRoutes;
