import React from "react";
import { Link } from "react-router-dom";
import { Employee, Referee } from "../types";
import { Eye, Pencil, Trash2 } from "lucide-react";

interface RefereeProps {
  employee: Employee;
}

const Referees: React.FC<RefereeProps> = ({employee}) => {
  return (
    <React.Fragment>
      <div className="flex items-center gap-3 mb-4">
        <h5 className="underline grow">Referees</h5>
        <div className="shrink-0">
          <button data-modal-target="addReferees" type="button"
                  className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
            Add Referee
          </button>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full align-middle border-separate whitespace-nowrap border-spacing-y-1">
          <thead className="text-left bg-white dark:bg-zink-700">
          <tr>
            <th className="px-3.5 py-2.5 font-semibold border-b border-transparent">
              <div className="flex items-center h-full">
                <input id="Checkbox1"
                       className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                       type="checkbox" defaultValue=""/>
              </div>
            </th>
            <th className="px-3.5 py-2.5 font-semibold border-b border-transparent">Institution Name</th>
            <th className="px-3.5 py-2.5 font-semibold border-b border-transparent">Name</th>
            <th className="px-3.5 py-2.5 font-semibold border-b border-transparent">Position</th>
            <th className="px-3.5 py-2.5 font-semibold border-b border-transparent">Mobile Number</th>
            <th className="px-3.5 py-2.5 font-semibold border-b border-transparent">Email</th>
            <th className="px-3.5 py-2.5 font-semibold border-b border-transparent">Address</th>
            <th className="px-3.5 py-2.5 font-semibold border-b border-transparent text-right">Action</th>
          </tr>
          </thead>
          <tbody>
          {employee && (employee.references || [])?.map((item: Referee, index: number) => (
            <tr key={index} className="bg-white dark:bg-zink-700">
              <td className="px-3.5 py-2.5 border-y border-transparent">
                <div className="flex items-center h-full">
                  <input
                    id={`Checkbox${item.id}`}
                    className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                    type="checkbox"
                    value=""
                  />
                </div>
              </td>
              <td className="px-3.5 py-2.5 border-y border-transparent">{item.company}</td>
              <td className="px-3.5 py-2.5 border-y border-transparent">{item.fullName}</td>
              <td className="px-3.5 py-2.5 border-y border-transparent">{item.status}</td>
              <td className="px-3.5 py-2.5 border-y border-transparent">{item.mobileNo}</td>
              <td className="px-3.5 py-2.5 border-y border-transparent">
                <Link to="#!"
                      className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600">{item.email}
                </Link>
              </td>
              <td className="px-3.5 py-2.5 border-y border-transparent">{item.telephoneNo}</td>
              <td className="px-3.5 py-2.5 border-y border-transparent">
                <div className="flex items-center justify-end gap-2">
                  <a href="#!"
                     className="flex items-center justify-center size-8 transition-all duration-150 ease-linear rounded-md bg-slate-100 hover:bg-slate-200 dark:bg-zink-600 dark:hover:bg-zink-500">
                    <Eye className="size-3"></Eye>
                  </a>
                  <a href="#!"
                     className="flex items-center justify-center size-8 transition-all duration-150 ease-linear rounded-md bg-slate-100 hover:bg-slate-200 dark:bg-zink-600 dark:hover:bg-zink-500">
                    <Pencil className="size-3"></Pencil>
                  </a>
                  <a href="#!"
                     className="flex items-center justify-center size-8 transition-all duration-150 ease-linear rounded-md bg-slate-100 hover:bg-slate-200 dark:bg-zink-600 dark:hover:bg-zink-500">
                    <Trash2 className="size-3"></Trash2>
                  </a>
                </div>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
}

export default Referees;