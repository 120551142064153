export const VIEW_TENANT_LIST = "";
export const VIEW_TENANT = "";
export const UPDATE_TENANT_LIST = "";
export const DELETE_TENANT_LIST = "";
export const APPROVE_TENANT_LIST = "";

export const TenantStatuses = [
  {value: '', label: 'Select Status'},
  {value: 'active', label: 'Active'},
  {value: 'draft', label: 'Draft'},
  {value: 'pendingApproval', label: 'Pending Approval'},
];
