import React, { useCallback, useEffect, useState } from 'react';
import Modal from "../../../../Common/Components/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Permission } from "../types";
import { useNavigate } from "react-router-dom";
import useReduxPost from "../../../../Common/hooks/redux-post";
import { useReduxFetch } from "../../../../Common/hooks";
import WpLoader from "../../../../Common/Components/loader";
import { addPermissionList, getPermissionList, getSinglePermission, updatePermissionList } from "../slices/thunk";

interface AddUpdateProps {
  id?: string
}

/**
 * @function
 * @name AddUpdatePermission
 * @description add/update
 * @version 1.0.0
 * @since 1.0.0
 * @author Muhammad Mwinchande <ammwinchande@gmail.com>
 * @returns {object}
 */
const AddUpdatePermission: React.FC<AddUpdateProps> = ({id}) => {
  const navigate = useNavigate();
  const reduxPost = useReduxPost();
  const reduxFetch = useReduxFetch();

  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isViewOnly, setIsViewOnly] = useState<boolean>(false);
  const [eventData, setEventData] = useState<Permission>();
  const [isLoading, setIsLoading] = useState<boolean>(false);


  useEffect(() => {
    if (id) {
      console.log("ID Supplied: ", id)
      setIsLoading(true);
      reduxFetch(getSinglePermission, id)
        .then((data: any) => {
          console.log("Response returned: ", data)
          validation.values(data)
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err?.errors) {
            validation.errors(err?.errors);
          }
        });
    }
  }, []);

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: eventData?.name ?? '',
      code: eventData?.description ?? '',
      status: eventData?.moduleName ?? '',
      id: eventData?.id ?? '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      code: Yup.string().required("Please Enter Code"),
      status: Yup.string().required("Please Enter Status"),
      id: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      if (id) {
        setIsLoading(true);
        values = {
          ...values,
          id
        };
        put(values);

        return;
      }

      post(values);
    },
  });
  const handleCancel = () => {
    navigate("..");
  };

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData(undefined);
      setIsEdit(false);
      setIsViewOnly(false);
    } else {
      setShow(true);
      setEventData(undefined);
      validation.resetForm();
    }
  }, [show, validation]);

  const put = (values: any) => {
    setIsLoading(true);
    reduxPost(updatePermissionList, values)
      .then(() => {
        setIsLoading(false);
        reduxFetch(getPermissionList);
        handleCancel();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const post = (values: any) => {
    setIsLoading(true);
    reduxPost(addPermissionList, values)
      .then(() => {
        setIsLoading(false);
        reduxFetch(getPermissionList);
        validation.values.reset()
      })
      .catch(() => {
        setIsLoading(false);
      });
  };


  return (
    <Modal show={true} onHide={toggle} id="defaultModal" modal-center="true"
           className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
           dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600">
      <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
        <Modal.Title className="text-16">{isEdit ? "Edit Tenant" : "Add Tenant"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
        {isLoading
          ? <WpLoader/>
          : <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-3">
              <label htmlFor="code" className="inline-block mb-2 text-base font-medium">
                Code
              </label>
              <input type="text" id="code"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     disabled
                     value={validation.values.code || '#TW1500004'}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="userNameInput"
                     className="inline-block mb-2 text-base font-medium">
                Name
              </label>
              <input type="text" id="userNameInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Enter name"
                     name="name"
                     readOnly={isViewOnly}
                     onChange={validation.handleChange}
                     value={validation.values.name || ""}
              />
              {validation.touched.name && validation.errors.name ? (
                <p className="text-red-400">{validation.errors.name}</p>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="statusSelect"
                     className="inline-block mb-2 text-base font-medium">
                Status
              </label>
              <select
                className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                data-choices=""
                data-choices-search-false=""
                id="statusSelect"
                name="status"
                disabled={isViewOnly}
                onChange={validation.handleChange}
                value={validation.values.status || ""}
              >
                <option value="">Select Status</option>
                <option value="active">Active</option>
                <option value="draft">Draft</option>
                <option value="pendingApproval">Pending Approval</option>
              </select>
              {validation.touched.status && validation.errors.status ? (
                <p className="text-red-400">{validation.errors.status}</p>
              ) : null}
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button type="reset" data-modal-close="addDocuments"
                      className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                      onClick={toggle}>Cancel
              </button>
              <button type="submit"
                      className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                {isEdit ? "Update Tenant" : "Add Tenant"}
              </button>
            </div>
          </form>
        }
      </Modal.Body>
    </Modal>
  );
}

export default AddUpdatePermission;
