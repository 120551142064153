import { createSlice } from "@reduxjs/toolkit";
import { addRoleList, deleteRoleList, getRoleList, getSingleRole, updateRoleList } from './thunk';
import { RootState } from "../../../../slices";

export const initialState = {
  roleList: [],
  errors: {}
};

const RolesSlice = createSlice({
  name: 'Roles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // List
    builder.addCase(getRoleList.fulfilled, (state: any, action: any) => {
      state.roleList = action.payload;
    });
    builder.addCase(getRoleList.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(getSingleRole.fulfilled, (state: any, action: any) => {
      state.roleList = action.payload;
    });
    builder.addCase(getSingleRole.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(addRoleList.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(addRoleList.fulfilled, (state: any, action: any) => {
      state.roleList = state.roleList.map((list: any) =>
        list.id === action.payload.id
          ? {...list, ...action.payload}
          : list
      );
    });
    builder.addCase(updateRoleList.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(updateRoleList.fulfilled, (state: any, action: any) => {
      state.roleList = state.roleList.filter(
        (roleList: any) => roleList.id.toString() !== action.payload.toString()
      );
    });
    builder.addCase(deleteRoleList.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });


  }
});

export const selectDataList = (state: RootState) => state.roles;

export default RolesSlice.reducer;