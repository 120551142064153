import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    addPermissionList as addPermissionListApi,
    deletePermissionList as deletePermissionListApi,
    getPermissionList as getPermissionListApi,
    getSinglePermission as getSinglePermissionApi,
    updatePermissionList as updatePermissionListApi,
} from "../../../../helpers/backend_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { GetPermissionPayload } from "../types/permission";
import { AxiosError } from "axios";

export const getPermissionList = createAsyncThunk<
  any,
  GetPermissionPayload,
  { rejectValue: any }
>(
  "permissions/getPermissionList", async (payload, {rejectWithValue}) => {
    try {
      const response = await getPermissionListApi(payload);
      console.log('Permission response: ', response)
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export const getSinglePermission = createAsyncThunk("permissions/getSingle", async (id: string) => {
  try {
    const response = await getSinglePermissionApi(id);

    console.log("Single Employee Response: ", response)

    return response;
  } catch (error: any) {
    const err: AxiosError<any> = error;

    if (!err.response) {
      return err
    }

    return err;
  }
});

export const addPermissionList = createAsyncThunk("permissions/addPermissionList", async (event: any) => {
  try {
    const response = await addPermissionListApi(event);
    toast.success("Permission Added Successfully", {autoClose: 2000});
    return response;
  } catch (error) {
    toast.error("Permission Added Failed", {autoClose: 2000});
    return error;
  }
});

export const updatePermissionList = createAsyncThunk("permissions/updatePermissionList", async (event: any) => {
  try {
    const response = await updatePermissionListApi(event);
    toast.success("Permission updated Successfully", {autoClose: 2000});
    return response;
  } catch (error) {
    toast.error("Permission updated Failed", {autoClose: 2000});
    return error;
  }
});

export const deletePermissionList = createAsyncThunk("permissions/deletePermissionList", async (event: any) => {
  try {
    const response = await deletePermissionListApi(event);
    toast.success("Permission deleted Successfully", {autoClose: 2000});
    return response;
  } catch (error) {
    toast.error("Permission deleted Failed", {autoClose: 2000});
    return error;
  }
});
