import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  addUserGrid as addUserGridApi,
  addUserList as addUserListApi,
  deleteUserGrid as deleteUserGridApi,
  deleteUserList as deleteUserListApi,
  getSingleUser as getSingleUserApi,
  getUserGrid as getUserGridApi,
  getUserList as getUserListApi,
  updateUserGrid as updateUserGridApi,
  updateUserList as updateUserListApi,
} from "../../../../helpers/backend_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { GetUserPayload } from "../types/user";
import { AxiosError } from "axios";

export const getUserList = createAsyncThunk<
  any, GetUserPayload, { rejectValue: any }
>("users/getUserList", async (payload, {rejectWithValue}) => {
  try {
    return await getUserListApi(payload);
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getSingleUser = createAsyncThunk("users/getSingle", async (id: string) => {
  try {
    const response = await getSingleUserApi(id);

    console.log("Single user Response: ", response)

    return response;
  } catch (error: any) {
    const err: AxiosError<any> = error;

    if (!err.response) {
      return err
    }

    return err;
  }
});
export const addUserList = createAsyncThunk("users/addUserList", async (event: any) => {
  try {
    const response = await addUserListApi(event);
    toast.success("User Added Successfully", {autoClose: 2000});
    return response;
  } catch (error) {
    toast.error("User Added Failed", {autoClose: 2000});
    return error;
  }
});
export const updateUserList = createAsyncThunk("users/updateUserList", async (event: any) => {
  try {
    const response = await updateUserListApi(event);
    toast.success("User updated Successfully", {autoClose: 2000});
    return response;
  } catch (error) {
    toast.error("User updated Failed", {autoClose: 2000});
    return error;
  }
});
export const deleteUserList = createAsyncThunk("users/deleteUserList", async (event: any) => {
  try {
    const response = await deleteUserListApi(event);
    toast.success("User deleted Successfully", {autoClose: 2000});
    return response;
  } catch (error) {
    toast.error("User deleted Failed", {autoClose: 2000});
    return error;
  }
});

export const getUserGrid = createAsyncThunk("users/getUserGrid", async () => {
  try {
    return await getUserGridApi();
  } catch (error) {
    return error;
  }
});
export const addUserGrid = createAsyncThunk("users/addUserGrid", async (event: any) => {
  try {
    const response = await addUserGridApi(event);
    toast.success("User Added Successfully", {autoClose: 2000});
    return response;
  } catch (error) {
    toast.error("User Added Failed", {autoClose: 2000});
    return error;
  }
});
export const updateUserGrid = createAsyncThunk("users/updateUserGrid", async (event: any) => {
  try {
    const response = await updateUserGridApi(event);
    toast.success("User updated Successfully", {autoClose: 2000});
    return response;
  } catch (error) {
    toast.error("User updated Failed", {autoClose: 2000});
    return error;
  }
});
export const deleteUserGrid = createAsyncThunk("users/deleteUserGrid", async (event: any) => {
  try {
    const response = await deleteUserGridApi(event);
    toast.success("User deleted Successfully", {autoClose: 2000});
    return response;
  } catch (error) {
    toast.error("User deleted Failed", {autoClose: 2000});
    return error;
  }
});
