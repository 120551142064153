import axios from "axios";
import { AuthPayload, LogoutResponse } from "../pages/types";
import { getBaseUrl } from "./utils/baseUrl";
import { environment } from "../environment";
import * as url from "./url_helper";

axios.defaults.baseURL = getBaseUrl();
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// content type
const authUser: any = localStorage.getItem("authUser")
const token = JSON.parse(authUser) ? JSON.parse(authUser).token : null;
if (token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;

      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: any) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */
  get = async (url: any, params: any): Promise<any> => {
    console.log("URL, params: ", url, params);

    let response: any;
    let paramKeys: any = [];

    if (params) {
      Object.keys(params).forEach(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys?.length ? paramKeys.join('&') : "";
      response = await axios.get(`${url}?${queryString}`, params);
    } else {
      response = await axios.get(`${url}`, params);
    }

    const {body} = response;

    console.log("Retrieved data: ", body)

    return body;
  };

  /**
   * Fetches data from given url
   */
  getSingle = async (url: any, id: string): Promise<any> => {
    url = url + '/' + id;
    let response: any = await axios.get(`${url}`);
    const {body} = response;
    console.log("Retrieved Single data: ", body)
    return body;
  };

  /**
   * post given data to url
   */
  create = async (url: any, payload: any): Promise<any> => {
    console.log("URL, payload: ", url, payload);
    const response = await axios.post(url, payload);
    console.log("Response", response);
    const {data} = response;
    console.log(data)
    return data;
  };

  /**
   * Updates data
   */
  update = async (url: any, payload: any): Promise<any> => {
    const response = await axios.patch(url, payload);
    const {data} = response;

    return data
  };

  put = async (url: any, payload: any): Promise<any> => {
    const response = await axios.put(url, payload);
    const {data} = response;

    return data;
  };

  /**
   * Delete
   */
  delete = async (url: any, config: any): Promise<any> => {
    const response = await axios.delete(url, {...config});
    const {data} = response;

    return data;
  };

  login = async (credentials: AuthPayload) => {
    if (environment.defaultAuth === "fake") {
      return axios.post(url.POST_FAKE_LOGIN, credentials);
    }

    return axios.post('/provisioner/auth/login', credentials);
  }

  logout = async (): Promise<LogoutResponse> => {
    const logoutPayload = {
      username: getLoggedUser().userName,
    }

    console.log('Logout payload: ', logoutPayload);

    return await axios.post('/provisioner/auth/logout', logoutPayload);
  }
}

const getLoggedUser = () => {
  const user = localStorage.getItem("authUser");
  console.log(user);
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedUser };
