import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from "./redux";
import { toast } from "react-toastify";

/**
 * Hook for posting data using redux
 * @version 0.0.4
 * @since 0.0.1
 * @author Muhammad Mwinchande
 * @returns Response from dispatch
 * @returns {Function} a post function to redux
 */
export default function useReduxPost() {
    const dispatch = useAppDispatch();

    /**
     * Function to post data using redux
     * @param action redux action
     * @param values values to post
     * @param canAlert
     * @version 0.0.4
     * @since 0.0.1
     * @author Muhammad Mwinchande
     * @returns Response from dispatch
     */
    const post = (action: any, values: any, canAlert = true) => {
        return dispatch(action(values))
            .then((response: any) => {
                if (canAlert) {
                    if (unwrapResult(response)?.message) {
                        toast.success(unwrapResult(response).message);
                    } else {
                        toast.success('Operation Successful', {autoClose: 2000});
                    }
                }
                return Promise.resolve(unwrapResult(response));
            })
            .catch((error: any) => {
                console.log('be err', error);
                if (error?.desc) {
                    toast.error(error.desc, {autoClose: 2000});
                } else if (error?.message) {
                    toast.error(error.message, {autoClose: 2000});
                } else if (
                    error?.statusCode &&
                    error?.statusDesc &&
                    error?.statusCode !== 200
                ) {
                    toast.error(error.statusDesc, {autoClose: 2000});
                } else {
                    toast.error('Internal Server Error. Contact Admin if you are online', {autoClose: 2000});
                }
                return Promise.reject(error);
            });
    };

    return post;
}
