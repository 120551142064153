import { environment } from "../../environment";

/**
 * @function
 * @name getBaseUrl
 * @description Get the base urls for uat and prod environments
 * @version 0.0.1
 * @since 0.0.1
 * @author Muhammad Mwinchande
 * @returns {object} Redirect urls
 */
export const getBaseUrl = (): string => {
    return environment.authUrl;
}
