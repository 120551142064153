import { createSlice } from "@reduxjs/toolkit";
import { addEmployeeSalary, deleteEmployeeSalary, getEmployeeSalary, updateEmployeeSalary } from "./thunk";
import { RootState } from "../../index";

export const initialState = {
    employeeSalarylist: [],
    error: {},
};

const SalarySlice = createSlice({
    name: "Salaries",
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        // Employee Salary
        builder.addCase(getEmployeeSalary.fulfilled, (state: any, action: any) => {
            state.employeeSalarylist = action.payload;
        });
        builder.addCase(getEmployeeSalary.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(addEmployeeSalary.fulfilled, (state: any, action: any) => {
            state.employeeSalarylist.unshift(action.payload);
        });
        builder.addCase(addEmployeeSalary.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(updateEmployeeSalary.fulfilled, (state: any, action: any) => {
            state.employeeSalarylist = state.employeeSalarylist.map((employeeSalarylist: any) =>
                employeeSalarylist.id === action.payload.id
                    ? {...employeeSalarylist, ...action.payload}
                    : employeeSalarylist
            );
        });
        builder.addCase(updateEmployeeSalary.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(deleteEmployeeSalary.fulfilled, (state: any, action: any) => {
            state.employeeSalarylist = state.employeeSalarylist.filter(
                (employeeSalarylist: any) => employeeSalarylist.id.toString() !== action.payload.toString()
            );
        });
        builder.addCase(deleteEmployeeSalary.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
    }
});

export const selectDataList = (state: RootState) => state.salaries;

export default SalarySlice.reducer;
