import LayoutReducer from "./layouts/reducer";
import LoginReducer from "./auth/login/reducer";
import RegisterReducer from "./auth/register/reducer";
import ProfileReducer from "./auth/profile/reducer";
import UsersReducer from "../app/modules/user/slices/reducer";
import TenantsReducer from "../app/modules/tenant/slices/reducer"
import RolesReducer from "../app/modules/role/slices/reducer";
import PermissionsReducer from "../app/modules/permission/slices/reducer";
import WorkflowsReducer from "./workflows/reducer";
import ProjectsReducer from "../app/modules/project/slices/reducer";
import OfficesReducer from "../app/modules/office/slices/reducer";
import { configureStore } from "@reduxjs/toolkit";
import { environment } from "../environment";
import EmployeesReducer from "../app/modules/employee/slices/reducer";
import HolidayReducer from "./hrManagement/holidays/reducer";
import SalaryReducer from "./hrManagement/salaries/reducer";
import EmployeeLeaveReducer from "./hrManagement/employee_leaves/reducer";
import AttendanceReducer from "./hrManagement/attendencies/reducer";

const rootReducer: any = {
  Layout: LayoutReducer,
  Login: LoginReducer,
  Register: RegisterReducer,
  Profile: ProfileReducer,
  employees: EmployeesReducer,
  users: UsersReducer,
  tenants: TenantsReducer,
  roles: RolesReducer,
  permissions: PermissionsReducer,
  projects: ProjectsReducer,
  workflows: WorkflowsReducer,
  offices: OfficesReducer,
  holidays: HolidayReducer,
  salaries: SalaryReducer,
  employee_leaves: EmployeeLeaveReducer,
  attendances: AttendanceReducer
};

const orderedReducers: any = Object.keys(rootReducer)
  .sort((a, b) => a.localeCompare(b))
  .reduce((obj: any, key: string) => {
    obj[key] = rootReducer[key];
    return obj;
  }, {});

const store = configureStore({
  reducer: orderedReducers,
  devTools: environment.defaultAuth === "dev"
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default rootReducer;
