const TenantsData = [
    {
        id: "6bb3b964-e376-40a9-9ce7-561879e64ec2",
        name: "Test Tenant5",
        code: "T1005",
        parentId: "",
        superTenant: true,
        status: "pendingApproval",
        statusRemark: "",
        createdBy: null,
        createdDate: "2024-05-11T10:07:46.675+00:00",
        lastModifiedBy: null,
        lastModifiedDate: "2024-05-11T10:12:27.326+00:00",
        address: null,
        projects: null,
        offices: null,
        users: null
    },
    {
        id: "6ed81b2f-c086-4caf-8b2f-bd481f392465",
        name: "Test Tenant1",
        code: "T1001",
        parentId: "",
        superTenant: false,
        status: "draft",
        statusRemark: "New Tenant Added",
        createdBy: null,
        createdDate: "2024-05-11T10:13:24.017+00:00",
        lastModifiedBy: null,
        lastModifiedDate: "2024-05-11T10:13:24.017+00:00",
        address: null,
        projects: null,
        offices: null,
        users: null
    },
    {
        id: "97341a6e-53e4-473f-8281-33e0ae30249c",
        name: "Test Tenant2",
        code: "T1002",
        parentId: "",
        superTenant: true,
        status: "active",
        statusRemark: "New Tenant Added",
        createdBy: null,
        createdDate: "2024-05-11T12:49:45.292+00:00",
        lastModifiedBy: null,
        lastModifiedDate: "2024-05-11T12:49:45.293+00:00",
        address: null,
        projects: null,
        offices: null,
        users: null
    }
];

export { TenantsData }
