import { createSlice } from "@reduxjs/toolkit";
import { addWorkflowList, deleteWorkflowList, getWorkflowList, updateWorkflowList } from './thunk';

export const initialState = {
    workflowList: [],
    errors: {}
};

const WorkflowsSlice = createSlice({
    name: 'Workflows',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // List
        builder.addCase(getWorkflowList.fulfilled, (state: any, action: any) => {
            state.workflowList = action.payload;
        });
        builder.addCase(getWorkflowList.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(addWorkflowList.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(addWorkflowList.fulfilled, (state: any, action: any) => {
            state.workflowList = state.workflowList.map((list: any) =>
                list.id === action.payload.id
                    ? {...list, ...action.payload}
                    : list
            );
        });
        builder.addCase(updateWorkflowList.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(updateWorkflowList.fulfilled, (state: any, action: any) => {
            state.workflowList = state.workflowList.filter(
                (workflowList: any) => workflowList.id.toString() !== action.payload.toString()
            );
        });
        builder.addCase(deleteWorkflowList.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });


    }
});

export default WorkflowsSlice.reducer;