import flagUs from "assets/images/flags/20/us.svg";
import flagTz from "assets/images/flags/20/tz.svg";


const languages = {
    en: {
        label: "English",
        flag: flagUs,
    },
    sw: {
        label: "Swahili",
        flag: flagTz,
    },
}

export default languages
