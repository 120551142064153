import React, { useEffect, useMemo, useState } from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import { Link } from 'react-router-dom';
import TableContainer from 'Common/TableContainer';
import { CheckCircle, Eye, Loader, Pencil, Plus, Search, Trash2, X } from 'lucide-react';
import DeleteModal from 'Common/DeleteModal';

import { deleteOffice as onDeleteOfficeList, getOffices as onGetOfficeList } from '../slices/thunk';
import { ToastContainer } from 'react-toastify';
import { selectDataList } from "../slices/reducer"
import { useAppDispatch, useAppSelector } from "../../../../Common/hooks/redux";
import WpLoader from "../../../../Common/Components/loader";
import { Office } from "../types";

/**
 * @function
 * @name Office
 * @description Office implementation
 * @version 0.1.0
 * @since 0.1.0
 * @author Muhammad Mwinchande <ammwinchande@gmail.com>
 * @returns {object}
 */
const OfficeList = () => {
  const dispatch = useAppDispatch();
  const {offices} = useAppSelector(selectDataList);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [eventData, setEventData] = useState<Office>();

  // Get Data
  useEffect(() => {
    setIsLoading(true); // Set loading to true when data fetch starts
    dispatch(onGetOfficeList({})).finally(() => setIsLoading(false));
  }, [dispatch]);

  // Delete Modal
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);

  // Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
    if (cell.id) {
      setEventData(cell);
    }
  };

  const handleDelete = () => {
    if (eventData) {
      dispatch(onDeleteOfficeList(eventData.id));
      setDeleteModal(false);
    }
  };

  // Search Data
  const filterSearchData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = ['name', 'status'];
    // filterDataBySearch(offices, search, keysToSearch, setOffice);
  };

  // columns
  const Status = ({item}: any) => {
    switch (item) {
      case "active":
        return (<span
          className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status"><CheckCircle
          className="size-3 mr-1.5"/>{item.toUpperCase()}</span>);
      case "draft":
        return (<span
          className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status"><Loader
          className="size-3 mr-1.5"/>{item.toUpperCase()}</span>);
      case "pendingApproval":
        return (<span
          className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent status"><X
          className="size-3 mr-1.5"/>{item.toUpperCase()}</span>);
      default:
        return <span
          className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status"><CheckCircle
          className="size-3 mr-1.5"/>{item.toUpperCase()}</span>;
    }
  };

  const columns = useMemo(() => [
      {
        header: (
          <div className="flex items-center h-full">
            <input id="CheckboxAll"
                   className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
                   type="checkbox"/>
          </div>
        ),
        enableSorting: false,
        id: "checkAll",
        cell: (cell: any) => {
          return (
            <div className="flex items-center h-full">
              <input id="Checkbox1"
                     className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
                     type="checkbox"/>
            </div>
          );
        }
      },
      {
        header: "Office ID",
        accessorKey: "tenantId",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <Link to="#!"
                className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600 code">{cell.getValue()}</Link>
        ),
      },
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: false
      },
      {
        header: "Address",
        accessorKey: "address",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <Status item={cell.getValue()}/>
        ),
      },
      {
        header: "Created By",
        accessorKey: "createdDate",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Created Date",
        accessorKey: "createdDate",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <div className="flex gap-3">
            <Link data-modal-target="addUserModal"
                  className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
                  to={'/apps-offices-list/update/' + cell.row.original.id}>
              <Eye className="inline-block size-4"/>
            </Link>
            <Link data-modal-target="addUserModal"
                  className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
                  to={'/apps-offices-list/update/' + cell.row.original.id}>
              <Pencil className="text-blue-700 inline-block size-3 ltr:mr-1 rtl:ml-1"/>
            </Link>
            <Link
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
              to="#!" onClick={() => {
              const orderData = cell.row.original;
              onClickDelete(orderData);
            }}>
              <Trash2 className="text-red-400 inline-block size-3 ltr:mr-1 rtl:ml-1"/>
            </Link>
          </div>
        ),
      }
    ], []
  );

  const handleChange = (selectedOption: any) => {
    if (selectedOption.value === 'Select Status' || selectedOption.value === 'hidden') {
      // setOffice(offices);
    }
    // else {
    //     const filteredOffices = offices.filter((data: any) => data.status === selectedOption.value);
    //     setOffice(filteredOffices);
    // }
    // TODO Add implementation for fetching using the redux
  };

  function retrieveData() {
    return offices && offices.length > 0 ? (
      <TableContainer
        isPagination={true}
        columns={(columns || [])}
        data={(offices || [])}
        customPageSize={7}
        divclassName="-mx-5 overflow-x-auto"
        tableclassName="w-full whitespace-nowrap"
        theadclassName="ltr:text-left rtl:text-right bg-slate-100 dark:bg-zink-600"
        thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-b border-slate-200 dark:border-zink-500"
        tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zink-500"
        PaginationClassName="flex flex-col items-center gap-4 px-4 mt-4 md:flex-row"
      />
    ) : (
      <div className="noresult">
        <div className="py-6 text-center">
          <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20"/>
          <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
          <p className="mb-0 text-slate-500 dark:text-zink-200">
            No Data Found.
          </p>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <BreadCrumb title='Offices List' pageTitle='Offices'/>
      <DeleteModal show={deleteModal} onHide={deleteToggle} onDelete={handleDelete}/>
      <ToastContainer closeButton={false} limit={1}/>
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="usersTable">
            <div className="card-body">
              <div className="flex items-center">
                <h6 className="text-15 grow">Offices</h6>
                <div className="shrink-0">
                  <Link data-modal-target="defaultModal"
                        className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
                        to="/apps-offices-list/add">
                    <button type="button"
                            className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                      <Plus className="inline-block size-2"/>
                      <span className="align-middle">Add Office</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
              <form action="#!">
                <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                  <div className="relative xl:col-span-2">
                    <input type="text"
                           className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                           placeholder="Search for name, status, code etc..."
                           autoComplete="off" onChange={(e) => filterSearchData(e)}/>
                    <Search
                      className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600"/>
                  </div>
                </div>
              </form>
            </div>
            <div className="card-body">
              {isLoading ? <WpLoader/> : retrieveData()}
            </div>
          </div>
        </div>
      </div>


      {/* Office Modal  */}
    </React.Fragment>
  );
};

export default OfficeList;
