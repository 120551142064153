import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { addHolidays, deleteHolidays, getHolidays, updateHolidays } from "./thunk";

export const initialState = {
    holidayslist: [],
    errors: {}
};

const HolidaySlice = createSlice({
    name: "Holidays",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getHolidays.fulfilled, (state: any, action: any) => {
            state.holidayslist = action.payload;
        });
        builder.addCase(getHolidays.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(addHolidays.fulfilled, (state: any, action: any) => {
            state.holidayslist.unshift(action.payload);
        });
        builder.addCase(addHolidays.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(updateHolidays.fulfilled, (state: any, action: any) => {
            state.holidayslist = state.holidayslist.map((holidayslist: any) =>
                holidayslist.id === action.payload.id
                    ? {...holidayslist, ...action.payload}
                    : holidayslist
            );
        });
        builder.addCase(updateHolidays.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(deleteHolidays.fulfilled, (state: any, action: any) => {
            state.holidayslist = state.holidayslist.filter(
                (holidayslist: any) => holidayslist.id.toString() !== action.payload.toString()
            );
        });
        builder.addCase(deleteHolidays.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
    }
});

export const selectDataList = (state: RootState) => state.holidays;

export default HolidaySlice.reducer;
