const RolesData = [
    {
        id: "0f6cd35e-d38d-4704-a732-0b2c9d00d8c4",
        tenant: "6bb3b964-e376-40a9-9ce7-561879e64ec2",
        name: "Tenant Role2",
        permissions: [
            "UserMng:create",
            "UserMng:delete",
            "UserMng:view",
            "UserMng:update",
            "UserMng:list",
            "UserMng:set-role",
            "UserMng:reset-password"
        ],
        active: false,
        createdBy: null,
        createdDate: "2024-05-11T15:55:06.296+00:00",
        lastModifiedBy: null,
        lastModifiedDate: "2024-05-11T20:20:42.231+00:00"
    },
    {
        id: "12556820-d6d9-4256-972c-e5afcf695b06",
        tenant: null,
        name: "Super Admin",
        permissions: [
            "UserMng:create",
            "UserMng:delete",
            "UserMng:view",
            "UserMng:update",
            "UserMng:list",
            "UserMng:set-role",
            "UserMng:reset-password"
        ],
        active: true,
        createdBy: null,
        createdDate: "2024-06-17T15:10:01.589+00:00",
        lastModifiedBy: null,
        lastModifiedDate: "2024-06-17T15:10:01.589+00:00"
    },
    {
        id: "61e3e722-2bfa-465c-aca8-8f46fdf7732a",
        tenant: null,
        name: "Role Name 2",
        permissions: [
            "UserMng:create",
            "UserMng:delete",
            "UserMng:view",
            "UserMng:update",
            "UserMng:list",
            "UserMng:set-role",
            "UserMng:reset-password"
        ],
        active: false,
        createdBy: null,
        createdDate: "2024-05-11T20:22:14.306+00:00",
        lastModifiedBy: null,
        lastModifiedDate: "2024-05-11T20:22:14.306+00:00"
    },
    {
        id: "6f7b9505-9fb6-403c-b0b6-59b7f04751e2",
        tenant: "6bb3b964-e376-40a9-9ce7-561879e64ec2",
        name: "Tenant Role1",
        permissions: [
            "UserMng:create",
            "UserMng:delete",
            "UserMng:view",
            "UserMng:update",
            "UserMng:list",
            "UserMng:set-role",
            "UserMng:reset-password"
        ],
        active: true,
        createdBy: null,
        createdDate: "2024-05-11T15:54:37.844+00:00",
        lastModifiedBy: null,
        lastModifiedDate: "2024-05-11T15:54:37.845+00:00"
    },
    {
        id: "cd4b0104-13bd-41d1-a57e-e64a4f876bf9",
        tenant: null,
        name: "Role Name 1",
        permissions: [
            "UserMng:create",
            "UserMng:delete",
            "UserMng:view",
            "UserMng:update",
            "UserMng:list",
            "UserMng:set-role",
            "UserMng:reset-password"
        ],
        active: false,
        createdBy: null,
        createdDate: "2024-05-11T20:21:54.937+00:00",
        lastModifiedBy: null,
        lastModifiedDate: "2024-05-11T20:23:14.568+00:00"
    }
];

export { RolesData };
