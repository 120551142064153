import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    addEmployeeSalary as addEmployeeSalaryApi,
    deleteEmployeeSalary as deleteEmployeeSalaryApi,
    getEmployeeSalary as getEmployeeSalaryApi,
    updateEmployeeSalary as updateEmployeeSalaryApi
} from "../../../helpers/backend_helper";
import { toast } from "react-toastify";


export const getEmployeeSalary = createAsyncThunk("hrManagement/getEmployeeSalary", async () => {
    try {
        return await getEmployeeSalaryApi();
    } catch (error) {
        return error;
    }
});
export const addEmployeeSalary = createAsyncThunk("hrManagement/addEmployeeSalary", async (event: any) => {
    try {
        const response = addEmployeeSalaryApi(event);
        const data = await response;
        toast.success("data Added Successfully", {autoClose: 2000});
        return data;
    } catch (error) {
        toast.error("data Added Failed", {autoClose: 2000});
        return error;
    }
});
export const updateEmployeeSalary = createAsyncThunk("hrManagement/updateEmployeeSalary", async (event: any) => {
    try {
        const response = updateEmployeeSalaryApi(event);
        const data = await response;
        toast.success("data updated Successfully", {autoClose: 2000});
        return data;
    } catch (error) {
        toast.error("data updated Failed", {autoClose: 2000});
        return error;
    }
});
export const deleteEmployeeSalary = createAsyncThunk("hrManagement/deleteEmployeeSalary", async (event: any) => {
    try {
        const response = deleteEmployeeSalaryApi(event);
        toast.success("data deleted Successfully", {autoClose: 2000});
        return response;
    } catch (error) {
        toast.error("data deleted Failed", {autoClose: 2000});
        return error;
    }
});
