import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    addRoleList as addRoleListApi,
    deleteRoleList as deleteRoleListApi,
    getRoleList as getRoleListApi,
    getSingleRole as getSingleRoleApi,
    updateRoleList as updateRoleListApi,
} from "../../../../helpers/backend_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { GetRolePayload } from "../types/role";
import { AxiosError } from "axios";

export const getRoleList = createAsyncThunk<
  any, GetRolePayload, { rejectValue: any }
>("roles/getRoleList", async (payload, {rejectWithValue}) => {
  try {
    const response = await getRoleListApi(payload);
    console.log('Role response: ', response)
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getSingleRole = createAsyncThunk("roles/getSingle", async (id: string) => {
  try {
    const response = await getSingleRoleApi(id);

    console.log("Single Role Response: ", response)

    return response;
  } catch (error: any) {
    const err: AxiosError<any> = error;

    if (!err.response) {
      return err
    }

    return err;
  }
});
export const addRoleList = createAsyncThunk("roles/addRoleList", async (event: any) => {
  try {
    const response = await addRoleListApi(event);
    toast.success("Role Added Successfully", {autoClose: 2000});
    return response;
  } catch (error) {
    toast.error("Role Added Failed", {autoClose: 2000});
    return error;
  }
});
export const updateRoleList = createAsyncThunk("roles/updateRoleList", async (event: any) => {
  try {
    const response = await updateRoleListApi(event);
    toast.success("Role updated Successfully", {autoClose: 2000});
    return response;
  } catch (error) {
    toast.error("Role updated Failed", {autoClose: 2000});
    return error;
  }
});
export const deleteRoleList = createAsyncThunk("roles/deleteRoleList", async (event: any) => {
  try {
    const response = await deleteRoleListApi(event);
    toast.success("Role deleted Successfully", {autoClose: 2000});
    return response;
  } catch (error) {
    toast.error("Role deleted Failed", {autoClose: 2000});
    return error;
  }
});
