import React from "react";
import { MoveRight, PencilIcon } from "lucide-react";

import { Address, ContactInfo, Employee, PaymentAccount } from "../types";

interface TableData {
  label: string;
  value?: string;
  link?: boolean
}

interface OverviewTabsProps {
  employee?: Employee;
}

const Overview: React.FC<OverviewTabsProps> = ({employee}) => {

  console.log("Called OverviewTabs: ", employee)

  const personalInfo: TableData[] = [
    {label: "Title", value: employee?.title ?? 'N/A'},
    {label: "First Name", value: employee?.firstName ?? 'N/A'},
    {label: "Last Name", value: employee?.sirName ?? 'N/A'},
    {label: "Other Names", value: employee?.otherNames ?? 'N/A'},
    {label: "Designation", value: employee?.jobTitle ?? 'N/A'},
    {label: "Birth of Date", value: employee?.birthDate ?? 'N/A'},
    {label: "Gender", value: employee?.gender ?? 'N/A'},
    {label: "Marital Status", value: employee?.maritalStatus ?? 'N/A'},
    {label: "Employee ID", value: employee?.employeeId ?? 'N/A'},
    {label: "Joining Date", value: employee?.joinDate ?? 'N/A'},
    {label: "Record Status", value: employee?.status ?? 'N/A'},
    {label: "Employment Status", value: employee?.employmentStatus ?? 'N/A'},
  ];

  return (
    <React.Fragment>
      <div className="grid grid-cols-1 gap-x-5 lg:grid-cols-2 2xl:grid-cols-2">

        <div className="card">
          <div className="card-body">
            <h6 className="mb-4 text-15">Personal Information</h6>
            <div className="overflow-x-auto">
              <table className="w-full ltr:text-left rtl:ext-right">
                <tbody>
                {
                  (personalInfo || [])?.map((item: TableData, index: number) => (
                    <tr key={index}>
                      <th className="py-2 font-semibold ps-0"
                          scope="row">{item.label}</th>
                      <td className="py-2 text-right text-slate-500 dark:text-zink-200">
                        {item.link ? <a href="http://watupro.tz/" rel="noopener"
                                        className="text-custom-500">www.watupro.tz</a> : item.value}
                      </td>
                    </tr>

                  ))
                }
                </tbody>
              </table>
            </div>
            <div className="mt-3 text-center">
              <a href="#!" className="inline-block underline text-custom-500">
                Update Info <MoveRight className="inline-block size-4 ml-2 rtl:-rotate-180"></MoveRight>
              </a>
            </div>
          </div>
        </div>
        {/*Personal Information */}
        <div>
          <div className="card">
            <div className="card-body">
              <h6 className="mb-4 text-15">Contact Details</h6>
              <div className="overflow-x-auto">
                <table className="w-full whitespace-nowrap">
                  <thead className="ltr:text-left rtl:text-right">
                  <tr>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">Type
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">Details
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">Emergency
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">
                      Status
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {employee &&
                    (employee.contacts || [])?.map((item: ContactInfo, index: number) => (
                      <tr key={index}>
                        <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">{item.type}</td>
                        <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">{item.value}</td>
                        <td
                          className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">{item.emergency ? 'YES' : 'NO'}</td>
                        <td
                          className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500 flex flex-row p-2">
                          <span
                            className="px-2.5 py-0.5 inline-block text-xs font-medium uppercase rounded border bg-sky-100 border-transparent text-sky-500 dark:bg-sky-500/20 dark:border-transparent">
                            {item.status}
                          </span>
                          <PencilIcon className="size-3 mr-1.5 text-yellow-500" onClick={(item) => {
                            console.log(item)
                          }}/>
                        </td>
                      </tr>

                    ))
                  }
                  </tbody>
                </table>
              </div>
              <div className="mt-3 text-center">
                <a href="#!" className="inline-block underline text-custom-500">Add New Contact <MoveRight
                  className="inline-block size-4 ml-2 rtl:-rotate-180"></MoveRight></a>
              </div>
            </div>
          </div>
          {/* Contact Details*/}

          <div className="card">
            <div className="card-body">
              <h6 className="mb-3 text-15">Addresses</h6>
              <div className="overflow-x-auto">
                <table className="w-full whitespace-nowrap">
                  <thead className="ltr:text-left rtl:text-right">
                  <tr>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">Type
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">Details
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">Emergency
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500">
                      Status
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {employee &&
                    (employee.addresses || [])?.map((item: Address, index: number) => (
                      <tr key={index}>
                        <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">{item.type}</td>
                        <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">{item.value}</td>
                        <td
                          className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">{item.emergency ? 'YES' : 'NO'}</td>
                        <td
                          className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500 flex flex-row p-2">
                          <span
                            className="px-2.5 py-0.5 inline-block text-xs font-medium uppercase rounded border bg-sky-100 border-transparent text-sky-500 dark:bg-sky-500/20 dark:border-transparent">
                            {item.status}
                          </span>
                          <PencilIcon className="size-3 mr-1.5 text-yellow-500" onClick={(item) => {
                            console.log(item)
                          }}/>
                        </td>
                      </tr>

                    ))
                  }
                  </tbody>
                </table>
              </div>
              <div className="mt-3 text-center">
                <a href="#!" className="inline-block underline text-custom-500">Add New Address <MoveRight
                  className="inline-block size-4 ml-2 rtl:-rotate-180"></MoveRight></a>
              </div>
            </div>
          </div>
          {/*  Addresses */}

          <div className="card">
            <div className="card-body">
              <h6 className="mb-3 text-15">Accounts</h6>

              <div className="flex flex-col gap-3">
                {employee &&
                  (employee.paymentAccounts || [])?.map((item: PaymentAccount, index: number) => (
                    <a key={index} href="#!"
                       className="flex items-center gap-3 p-2 border rounded-md border-slate-200 dark:border-zink-500">
                      <div className="flex items-center justify-center size-12 rounded-md shrink-0 mr-3">
                        <h5 className="mb-1 text-15 uppercase">{item.accountType}</h5>
                      </div>
                      <div className="grow">
                        <h5 className="mb-1 text-15 ">{item.accountNumber}</h5>
                        <p className="text-sm text-slate-500 dark:text-zink-200">
                          {item.provider} : {item.accountName}
                        </p>
                      </div>
                      <div className="flex flex-row gap-3">
                        <span
                          className="px-2.5 py-0.5 inline-block text-xs font-medium uppercase rounded border bg-sky-100 border-transparent text-sky-500 dark:bg-sky-500/20 dark:border-transparent">
                              {item.status}
                        </span>
                        <PencilIcon
                          className="size-3 mr-1.5 text-yellow-500"
                          onClick={(item) => {
                            console.log(item)
                          }}/>
                      </div>
                    </a>
                  ))
                }
              </div>
              <div className="mt-3 text-center">
                <a href="#!" className="inline-block underline text-custom-500">Add New Account <MoveRight
                  className="inline-block size-4 ml-2 rtl:-rotate-180"></MoveRight></a>
              </div>
            </div>
          </div>
          {/*  Payment Accounts */}

        </div>

      </div>
    </React.Fragment>
  );
}

export default Overview;