import React from "react";
import RoleList from "../pages";
import AddUpdateRole from "../pages/add_update";

interface RouteObject {
  path: string;
  component: React.ComponentType<any>;
  exact?: boolean;
}

const roleRoutes: Array<RouteObject> = [
  {path: '/apps-roles-list', component: RoleList},
  {path: '/apps-roles-list/add', component: AddUpdateRole},
  {path: '/apps-roles-list/update/:id', component: AddUpdateRole},
];

export default roleRoutes;
