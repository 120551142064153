import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    addOfficeList as addOfficeListApi,
    deleteOfficeList as deleteOfficeListApi,
    getOfficeList as getOfficeListApi,
    getSingleOffice as getSingleOfficeApi,
    updateOfficeList as updateOfficeListApi,
} from "../../../../helpers/backend_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { GetOfficePayload } from "../types/office";
import { AxiosError } from "axios";

export const getOffices = createAsyncThunk<
  any, GetOfficePayload, { rejectValue: any }
>("offices/getOfficeList", async () => {
  try {
    const response = await getOfficeListApi();
    console.log('Office response: ', response)
    return response;
  } catch (error) {
    return error;
  }
});
export const getSingleOffice = createAsyncThunk("offices/getSingle", async (id: string) => {
  try {
    const response = await getSingleOfficeApi(id);

    console.log("Single office Response: ", response)

    return response;
  } catch (error: any) {
    const err: AxiosError<any> = error;

    if (!err.response) {
      return err
    }

    return err;
  }
});
export const addOffice = createAsyncThunk("offices/addOfficeList", async (event: any) => {
  try {
    const response = await addOfficeListApi(event);
    toast.success("Office Added Successfully", {autoClose: 2000});
    return response;
  } catch (error) {
    toast.error("Office Added Failed", {autoClose: 2000});
    return error;
  }
});
export const updateOffice = createAsyncThunk("offices/updateOfficeList", async (event: any) => {
  try {
    const response = await updateOfficeListApi(event);
    toast.success("Office updated Successfully", {autoClose: 2000});
    return response;
  } catch (error) {
    toast.error("Office updated Failed", {autoClose: 2000});
    return error;
  }
});
export const deleteOffice = createAsyncThunk("offices/deleteOfficeList", async (event: any) => {
  try {
    const response = await deleteOfficeListApi(event);
    toast.success("Office deleted Successfully", {autoClose: 2000});
    return response;
  } catch (error) {
    toast.error("Office deleted Failed", {autoClose: 2000});
    return error;
  }
});
