const FriendsData = [
    {id: 1, name: "Aubrey Beer", username: "@aubrey_beer", joiningDate: "24 June, 2023", isFollow: false},
    {id: 2, name: "Clark Kris", username: "@kris120", joiningDate: "10 Aug, 2023", isFollow: true},
    {id: 3, name: "Lavern Ratke", username: "@lavern_r", joiningDate: "19 Sept, 2023", isFollow: true},
    {id: 4, name: "Eldora Stiedemann", username: "@e_stiedemann", joiningDate: "08 Oct, 2023", isFollow: false},
    {id: 5, name: "Jordane Dare", username: "@jordane_dare", joiningDate: "07 Dec, 2023", isFollow: false},
    {id: 6, name: "Ottilie Schmitt", username: "@ottilie", joiningDate: "01 Nov, 2023", isFollow: true},
    {id: 7, name: "Darron Shields", username: "@dr_darron", joiningDate: "11 Nov, 2023", isFollow: false},
    {id: 8, name: "Shanny Homenick", username: "@shanny_h", joiningDate: "16 July, 2023", isFollow: true},
    {id: 9, name: "Vaughn Leffler", username: "@vaughn_902", joiningDate: "09 Jan, 2023", isFollow: true},
    {id: 10, name: "Theodora Jones", username: "@jones", joiningDate: "02 Feb, 2023", isFollow: false},
];

const EventData = [
    {
        id: 1,
        eventName: "Business Conference",
        startDate: "03 June, 2023",
        endDate: "19 June, 2023",
        numberRegistered: "231",
        total: "1,000",
        status: "Ongoing"
    },
    {
        id: 2,
        eventName: "Graduation Ceremony",
        startDate: "01 Jan, 2023",
        endDate: "01 Jan, 2023",
        numberRegistered: "0",
        total: "1,500",
        status: "Draft"
    },
    {
        id: 3,
        eventName: "Cultural Festival",
        startDate: "05 Aug, 2023",
        endDate: "10 Aug, 2023",
        numberRegistered: "19,121",
        total: "20,000",
        status: "Closed"
    },
    {
        id: 4,
        eventName: "Award Ceremony",
        startDate: "11 Nov, 2023",
        endDate: "11 Nov, 2023",
        numberRegistered: "748",
        total: "1,100",
        status: "Ongoing"
    },
    {
        id: 5,
        eventName: "Sports Game",
        startDate: "01 Dec, 2023",
        endDate: "07 Dec, 2023",
        numberRegistered: "24,130",
        total: "30,000",
        status: "Ongoing"
    },
    {
        id: 6,
        eventName: "Theater Performance",
        startDate: "18 Feb, 2024",
        endDate: "18 Feb, 2024",
        numberRegistered: "0",
        total: "800",
        status: "Draft"
    },
    {
        id: 7,
        eventName: "Community Clean-Up Day",
        startDate: "15 Oct, 2023",
        endDate: "15 Oct, 2023",
        numberRegistered: "1,142",
        total: "1200",
        status: "Closed"
    },
];


export { FriendsData, EventData };
