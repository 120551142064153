import React, { useCallback, useEffect, useState } from 'react';
import Modal from "../../../../Common/Components/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Project } from "../types";
import { useNavigate } from "react-router-dom";
import useReduxPost from "../../../../Common/hooks/redux-post";
import { useReduxFetch } from "../../../../Common/hooks";
import WpLoader from "../../../../Common/Components/loader";
import { addProject, getProjectList, getSingleProject, updateProject } from "../slices/thunk";
import { GenericDataList } from "../../../../Common/types";
import { getTenantList } from "../../tenant/slices/thunk";
import { Tenant } from "../../tenant/types";
import { getOffices } from "../../office/slices/thunk";
import { Office } from "../../office/types";
import Select from "react-select";
import Flatpickr from "react-flatpickr";

interface AddUpdateProps {
  id?: string
}

/**
 * @function
 * @name AddUpdateProject
 * @description add/update
 * @version 1.0.0
 * @since 1.0.0
 * @author Muhammad Mwinchande <ammwinchande@gmail.com>
 * @returns {object}
 */
const AddUpdateProject: React.FC<AddUpdateProps> = ({id}) => {
  const navigate = useNavigate();
  const reduxPost = useReduxPost();
  const reduxFetch = useReduxFetch();

  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isViewOnly, setIsViewOnly] = useState<boolean>(false);
  const [eventData, setEventData] = useState<Project>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [tenants, setTenants] = useState<GenericDataList[]>([]);
  const [tenantId, setTenantId] = useState<any>([])

  const [offices, setOffices] = useState<GenericDataList[]>([]);
  const [officeId, setOfficeId] = useState<any>([]);


  useEffect(() => {
    if (id) {
      console.log("ID Supplied: ", id)
      setIsLoading(true);
      reduxFetch(getSingleProject, id)
        .then((data: any) => {
          console.log("Response returned: ", data)
          validation.values(data)
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err?.errors) {
            validation.errors(err?.errors);
          }
        });
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    reduxFetch(getTenantList)
      .then((data: Tenant[]) => {
        let tenantsList = data.map((tenant) => {
          return {
            id: tenant.id,
            name: tenant.name,
          };
        });
        // @ts-ignore
        setTenants(tenantsList);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    reduxFetch(getOffices)
      .then((data: Office[]) => {
        let officesList = data.map((office) => {
          return {
            id: office.id,
            name: office.name,
          };
        });
        // @ts-ignore
        setOffices(officesList);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, []);

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: eventData?.id ?? '',
      tenantId: eventData?.tenantId ?? '',
      officeId: eventData?.officeId ?? '',
      name: eventData?.name ?? '',
      description: eventData?.description ?? '',
      startDate: eventData?.startDate ?? '',
      endDate: eventData?.endDate ?? '',
    },
    validationSchema: Yup.object({
      id: Yup.string().notRequired(),
      tenantId: Yup.string().required("Please Choose Tenant"),
      officeId: Yup.string().required("Please Choose Office"),
      name: Yup.string().required("Please Enter Name"),
      description: Yup.string().required("Please Enter Description"),
      startDate: Yup.string().required("Please Enter Start Date"),
      endDate: Yup.string().required("Please Enter End Date"),
    }),
    onSubmit: (values) => {
      values = {
        ...values,
        tenantId: tenantId,
        officeId: officeId
      };

      if (id) {
        setIsLoading(true);
        values = {
          ...values,
          id
        };

        put(values);

        return;
      }

      post(values);
    },
  });
  const handleCancel = () => {
    navigate("..");
  };

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData(undefined);
      setIsEdit(false);
      setIsViewOnly(false);
    } else {
      setShow(true);
      setEventData(undefined);
      validation.resetForm();
    }
  }, [show, validation]);

  const put = (values: any) => {
    setIsLoading(true);
    reduxPost(updateProject, values)
      .then(() => {
        setIsLoading(false);
        reduxFetch(getProjectList);
        handleCancel();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const post = (values: any) => {
    setIsLoading(true);
    reduxPost(addProject, values)
      .then(() => {
        setIsLoading(false);
        reduxFetch(getProjectList);
        validation.values.reset()
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleTenantChange = (selectedOption: any) => {
    if (selectedOption.value === 'Select Tenant' || selectedOption.value === 'Hidden') {
      setTenantId(tenants);
    } else {
      const filteredTenants = tenants.filter((data: any) => data.status === selectedOption.value);
      setTenantId(filteredTenants);
    }
  };

  const handleOfficeChange = (selectedOption: any) => {
    if (selectedOption.value === 'Select Office' || selectedOption.value === 'Hidden') {
      setOfficeId(offices);
    } else {
      const filteredOffices = offices.filter((data: any) => data.status === selectedOption.value);
      setOfficeId(filteredOffices);
    }
  };


  return (
    <Modal show={true} onHide={toggle} id="defaultModal" modal-center="true"
           className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
           dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600">
      <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
        <Modal.Title className="text-16">{isEdit ? "Edit Project" : "Add Project"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
        {isLoading
          ? <WpLoader/>
          : <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-3">
              <label htmlFor="tenantSelect"
                     className="inline-block mb-2 text-base font-medium">
                Tenant
              </label>

              <Select
                className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                options={tenants}
                isSearchable={false}
                onChange={(event: any) => handleTenantChange(event)}
                id="tenantSelect"
                required={true}
              />
              {validation.touched.tenantId && validation.errors.tenantId ? (
                <p className="text-red-400">{validation.errors.tenantId}</p>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="officeSelect"
                     className="inline-block mb-2 text-base font-medium">
                Office
              </label>

              <Select
                className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                options={offices}
                isSearchable={false}
                onChange={(event: any) => handleOfficeChange(event)}
                id="officeSelect"
                required={true}
              />
              {validation.touched.officeId && validation.errors.officeId ? (
                <p className="text-red-400">{validation.errors.officeId}</p>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="userNameInput"
                     className="inline-block mb-2 text-base font-medium">
                Name
              </label>
              <input type="text" id="userNameInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Enter name"
                     name="name"
                     readOnly={isViewOnly}
                     onChange={validation.handleChange}
                     value={validation.values.name || ""}
              />
              {validation.touched.name && validation.errors.name ? (
                <p className="text-red-400">{validation.errors.name}</p>
              ) : null}
            </div>

            <div className="mb-3">
              <label htmlFor="descriptionInput"
                     className="inline-block mb-2 text-base font-medium">
                Description
              </label>
              <input type="text" id="descriptionInput"
                     className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                     placeholder="Enter description"
                     name="description"
                     readOnly={isViewOnly}
                     onChange={validation.handleChange}
                     value={validation.values.name || ""}
                     required={false}
              />
              {validation.touched.description && validation.errors.description ? (
                <p className="text-red-400">{validation.errors.description}</p>
              ) : null}
            </div>

            <div className="mb-3">
              <label htmlFor="dateInput"
                     className="inline-block mb-2 text-base font-medium">
                Start Date - End Date
              </label>
              <Flatpickr
                options={{
                  mode: "range",
                  dateFormat: "d M, Y",
                }}
                placeholder="Select Start and End Date"
                name="projectDate"
                required={true}
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              />
              {validation.touched.description && validation.errors.description ? (
                <p className="text-red-400">{validation.errors.description}</p>
              ) : null}
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button type="reset" data-modal-close="addDocuments"
                      className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                      onClick={toggle}>Cancel
              </button>
              <button type="submit"
                      className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                {isEdit ? "Update Project" : "Add Project"}
              </button>
            </div>
          </form>
        }
      </Modal.Body>
    </Modal>
  );
}

export default AddUpdateProject;
