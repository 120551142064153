import { createSlice } from "@reduxjs/toolkit";
import {
    addLeaveManageHR,
    deleteLeaveManageHR,
    getLeaveManageEmployee,
    getLeaveManageHR,
    updateLeaveManageHR
} from "./thunk";
import { RootState } from "../../index";

export const initialState = {
    leaveManageEmployeelist: [],
    error: {},
}

const EmployeeLeaveSlice = createSlice({
    name: "Leaves",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getLeaveManageEmployee.fulfilled, (state: any, action: any) => {
            state.leaveManageEmployeelist = action.payload;
        });
        builder.addCase(getLeaveManageEmployee.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });

        // Leave Manage HR
        builder.addCase(getLeaveManageHR.fulfilled, (state: any, action: any) => {
            state.leaveManageHRlist = action.payload;
        });
        builder.addCase(getLeaveManageHR.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(addLeaveManageHR.fulfilled, (state: any, action: any) => {
            state.leaveManageHRlist.unshift(action.payload);
        });
        builder.addCase(addLeaveManageHR.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(updateLeaveManageHR.fulfilled, (state: any, action: any) => {
            state.leaveManageHRlist = state.leaveManageHRlist.map((leaveManageHRlist: any) =>
                leaveManageHRlist.id === action.payload.id
                    ? {...leaveManageHRlist, ...action.payload}
                    : leaveManageHRlist
            );
        });
        builder.addCase(updateLeaveManageHR.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(deleteLeaveManageHR.fulfilled, (state: any, action: any) => {
            state.leaveManageHRlist = state.leaveManageHRlist.filter(
                (leaveManageHRlist: any) => leaveManageHRlist.id.toString() !== action.payload.toString()
            );
        });
        builder.addCase(deleteLeaveManageHR.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
    }
});

export const selectDataList = (state: RootState) => state.employeeLeaves;

export default EmployeeLeaveSlice.reducer;