import React from 'react';
import { Loader } from "lucide-react";

function WpLoader() {
    return (
        <div className="flex justify-center items-center h-48">
            <Loader
                className="animate-spin size-10 text-custom-500"/>
        </div>
    );
}

export default WpLoader;
