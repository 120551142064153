import { createSlice } from "@reduxjs/toolkit";
import { addTenantList, deleteTenantList, getSingleTenant, getTenantList, updateTenantList } from './thunk';
import { RootState } from "../../../../slices";

export const initialState = {
    tenantList: [],
    errors: {}
};

const TenantsSlice = createSlice({
    name: 'Tenants',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTenantList.fulfilled, (state: any, action: any) => {
            state.tenantList = action.payload;
        });
        builder.addCase(getTenantList.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });

        builder.addCase(getSingleTenant.fulfilled, (state: any, action: any) => {
            state.tenantList = action.payload;
        });
        builder.addCase(getSingleTenant.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });

        builder.addCase(addTenantList.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(addTenantList.fulfilled, (state: any, action: any) => {
            state.tenantList = state.tenantList.map((list: any) =>
                list.id === action.payload.id
                    ? {...list, ...action.payload}
                    : list
            );
        });
        builder.addCase(updateTenantList.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(updateTenantList.fulfilled, (state: any, action: any) => {
            state.tenantList = state.tenantList.filter(
                (tenantList: any) => tenantList.id.toString() !== action.payload.toString()
            );
        });
        builder.addCase(deleteTenantList.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });


    }
});

export const selectDataList = (state: RootState) => state.tenants

export default TenantsSlice.reducer;
