import { createSlice } from "@reduxjs/toolkit";
import { getAttendance, getMainAttendance } from "./thunk";
import { RootState } from "../../index";

export const initialState = {
    attendanceList: [],
    errors: {}
};

const AttendanceSlice = createSlice({
    name: "Attendances",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAttendance.fulfilled, (state: any, action: any) => {
            state.attendancelist = action.payload;
        });
        builder.addCase(getAttendance.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });

        builder.addCase(getMainAttendance.fulfilled, (state: any, action: any) => {
            state.mainAttendancelist = action.payload;
        });
        builder.addCase(getMainAttendance.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
    }
});

export const selectDataList = (state: RootState) => state.attendances;

export default AttendanceSlice.reducer;
