import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    addHolidays as addHolidaysApi,
    deleteHolidays as deleteHolidaysApi,
    getHolidays as getHolidaysApi,
    updateHolidays as updateHolidaysApi
} from "../../../helpers/backend_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { GetHolidayPayload } from "../../../pages/HRManagement/types";

export const getHolidays = createAsyncThunk<
    any, GetHolidayPayload, { rejectValue: any }
>("hrManagement/getHolidays", async (payload, {rejectWithValue}) => {
    try {
        return await getHolidaysApi();
    } catch (error) {
        return rejectWithValue(error);
    }
});
export const addHolidays = createAsyncThunk("hrManagement/addHolidays", async (event: any) => {
    try {
        const response = await addHolidaysApi(event);
        toast.success("Holidays Added Successfully", {autoClose: 2000});
        return response;
    } catch (error) {
        toast.error("Holidays Added Failed", {autoClose: 2000});
        return error;
    }
});
export const updateHolidays = createAsyncThunk("hrManagement/updateHolidays", async (event: any) => {
    try {
        const response = await updateHolidaysApi(event);
        toast.success("Holidays updated Successfully", {autoClose: 2000});
        return response;
    } catch (error) {
        toast.error("Holidays updated Failed", {autoClose: 2000});
        return error;
    }
});
export const deleteHolidays = createAsyncThunk("hrManagement/deleteHolidays", async (event: any) => {
    try {
        const response = await deleteHolidaysApi(event);
        toast.success("Holidays deleted Successfully", {autoClose: 2000});
        return response;
    } catch (error) {
        toast.error("Holidays deleted Failed", {autoClose: 2000});
        return error;
    }
});
