export const VIEW_USER_LIST = "";
export const VIEW_USER = "";
export const UPDATE_USER_LIST = "";
export const DELETE_USER_LIST = "";
export const APPROVE_USER_LIST = "";

export const UserStatuses = [
  {value: '', label: 'Select Status'},
  {value: 'active', label: 'Active'},
  {value: 'draft', label: 'Draft'},
  {value: 'pendingApproval', label: 'Pending Approval'},
];
