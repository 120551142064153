import { createSlice } from "@reduxjs/toolkit";
import { addOffice, deleteOffice, getOffices, getSingleOffice, updateOffice } from './thunk';
import { RootState } from "../../../../slices";

export const initialState = {
  officeList: [],
  errors: {}
};

const OfficesSlice = createSlice({
  name: 'Offices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOffices.fulfilled, (state: any, action: any) => {
      state.officeList = action.payload;
    });
    builder.addCase(getOffices.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(addOffice.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(addOffice.fulfilled, (state: any, action: any) => {
      state.officeList = state.officeList.map((list: any) =>
        list.id === action.payload.id
          ? {...list, ...action.payload}
          : list
      );
    });
    builder.addCase(getSingleOffice.fulfilled, (state: any, action: any) => {
      state.officeList = action.payload;
    });
    builder.addCase(getSingleOffice.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(updateOffice.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });
    builder.addCase(updateOffice.fulfilled, (state: any, action: any) => {
      state.officeList = state.officeList.filter(
        (officeList: any) => officeList.id.toString() !== action.payload.toString()
      );
    });
    builder.addCase(deleteOffice.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });


  }
});

export const selectDataList = (state: RootState) => state.offices;

export default OfficesSlice.reducer;
