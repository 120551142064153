import { APIClient } from "./api_helper";

import * as url from "./url_helper";
import { GetEmployeePayload } from "../pages/HRManagement/types";
import { AuthPayload } from "../pages/types";

const api = new APIClient();
// Gets the logged-in user data from local session

// Gets the logged-in user data from local session
export const getLoggedUser = () => {
    const user = localStorage.getItem("user");
    if (user) return JSON.parse(user);
    return null;
};

// is user is logged in
export const isUserAuthenticated = () => {
    return getLoggedUser() !== null;
};

// Register Method
export const postFakeRegister = (data: any) => api.create(url.POST_FAKE_REGISTER, data);

// Login Method
export const postFakeLogin = (data: AuthPayload) => api.login(data);

// postForgetPwd
export const postFakeForgetPwd = (data: any) => api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
export const postJwtProfile = (data: any) => api.create(url.POST_EDIT_JWT_PROFILE, data);

export const postFakeProfile = (data: any) => api.create(url.POST_EDIT_PROFILE, data);
// export const postFakeProfile = (data: any) => api.update(url.POST_EDIT_PROFILE + '/' + data.idx, data);

// Register Method
export const postJwtRegister = (url: any, data: any) => {
    return api.create(url, data)
        .catch((err: any) => {
            var message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 404:
                        message = "Sorry! the page you are looking for could not be found";
                        break;
                    case 500:
                        message = "Sorry! something went wrong, please contact our support team";
                        break;
                    case 401:
                        message = "Invalid credentials";
                        break;
                    default:
                        message = err[1];
                        break;
                }
            }
            throw message;
        });
};
// Login Method
export const postJwtLogin = (data: any) => api.create(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
export const postJwtForgetPwd = (data: any) => api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// Overview
export const getReview = () => api.get(url.GET_REVIEW, null);
export const addReview = (data: any) => api.create(url.ADD_REVIEW, data);
export const updateReview = (data: any) => api.update(url.UPDATE_REVIEW, data);
export const deleteReview = (data: any) => api.delete(url.DELETE_REVIEW, {headers: {data}});

// HR Management
// Employee List
export const getEmployee = (payload: GetEmployeePayload) => api.get(url.GET_EMPLOYEE, payload);
export const addEmployee = (data: any) => api.create(url.ADD_EMPLOYEE, data);
export const updateEmployee = (data: any) => api.update(url.UPDATE_EMPLOYEE, data);
export const deleteEmployee = (data: any) => api.delete(url.DELETE_EMPLOYEE, {headers: {data}});

// Holidays
export const getHolidays = () => api.get(url.GET_HOLIDAYS, null);
export const addHolidays = (data: any) => api.create(url.ADD_HOLIDAYS, data);
export const updateHolidays = (data: any) => api.update(url.UPDATE_HOLIDAYS, data);
export const deleteHolidays = (data: any) => api.delete(url.DELETE_HOLIDAYS, {headers: {data}});

// Leaves Manage

// Leave Manage (Employee)
export const getLeaveManageEmployee = () => api.get(url.GET_LEAVE_MANAGE_EMPLOYEE, null);

// Leave Manage (HR)
export const getLeaveManageHR = () => api.get(url.GET_LEAVE_MANAGE_HR, null);
export const addLeaveManageHR = (data: any) => api.create(url.ADD_LEAVE_MANAGE_HR, data);
export const updateLeaveManageHR = (data: any) => api.update(url.UPDATE_LEAVE_MANAGE_HR, data);
export const deleteLeaveManageHR = (data: any) => api.delete(url.DELETE_LEAVE_MANAGE_HR, {headers: {data}});

// Attendance
// Attendance (HR)
export const getAttendance = () => api.get(url.GET_ATTENDANCE, null);

// Main Attendance
export const getMainAttendance = () => api.get(url.GET_MAIN_ATTENDANCE, null);

// Offices
export const getDepartments = () => api.get(url.GET_DEPARTMENTS, null);
export const addDepartments = (data: any) => api.create(url.ADD_DEPARTMENTS, data);
export const updateDepartments = (data: any) => api.update(url.UPDATE_DEPARTMENTS, data);
export const deleteDepartments = (data: any) => api.delete(url.DELETE_DEPARTMENTS, {headers: {data}});

// Sales
// Estimates
export const getEstimates = () => api.get(url.GET_ESTIMATES, null);
export const addEstimates = (data: any) => api.create(url.ADD_ESTIMATES, data);
export const updateEstimates = (data: any) => api.update(url.UPDATE_ESTIMATES, data);
export const deleteEstimates = (data: any) => api.delete(url.DELETE_ESTIMATES, {headers: {data}});

// Payments
export const getPayments = () => api.get(url.GET_PAYMENTS, null);

// Expenses
export const getExpenses = () => api.get(url.GET_EXPENSES, null);
export const addExpenses = (data: any) => api.create(url.ADD_EXPENSES, data);
export const updateExpenses = (data: any) => api.update(url.UPDATE_EXPENSES, data);
export const deleteExpenses = (data: any) => api.delete(url.DELETE_EXPENSES, {headers: {data}});

// Payroll
// Employee Salary
export const getEmployeeSalary = () => api.get(url.GET_EMPLOYEE_SALARY, null);
export const addEmployeeSalary = (data: any) => api.create(url.ADD_EMPLOYEE_SALARY, data);
export const updateEmployeeSalary = (data: any) => api.update(url.UPDATE_EMPLOYEE_SALARY, data);
export const deleteEmployeeSalary = (data: any) => api.delete(url.DELETE_EMPLOYEE_SALARY, {headers: {data}});

// Users
// List View
export const getUserList = () => api.get(url.GET_USER_LIST, null);
export const addUserList = (data: any) => api.create(url.ADD_USER_LIST, data);
export const updateUserList = (data: any) => api.update(url.UPDATE_USER_LIST, data);
export const deleteUserList = (user: any) => api.delete(url.DELETE_USER_LIST, {headers: {user}});

// Grid View
export const getUserGrid = () => api.get(url.GET_USER_GRID, null);
export const addUserGrid = (data: any) => api.create(url.ADD_USER_GRID, data);
export const updateUserGrid = (data: any) => api.update(url.UPDATE_USER_GRID, data);
export const deleteUserGrid = (user: any) => api.delete(url.DELETE_USER_GRID, {headers: {user}});

// Tenants
export const getTenantList = () => api.get(url.GET_TENANT_LIST, null);
export const addTenantList = (data: any) => api.create(url.ADD_TENANT_LIST, data);
export const updateTenantList = (data: any) => api.update(url.UPDATE_TENANT_LIST, data);
export const deleteTenantList = (tenant: any) => api.delete(url.DELETE_TENANT_LIST, {headers: {tenant}});

// Offices
export const getOfficeList = () => api.get(url.GET_OFFICE_LIST, null);
export const addOfficeList = (data: any) => api.create(url.ADD_OFFICE_LIST, data);
export const updateOfficeList = (data: any) => api.update(url.UPDATE_OFFICE_LIST, data);
export const deleteOfficeList = (office: any) => api.delete(url.DELETE_OFFICE_LIST, {headers: {office}});

// Roles
export const getRoleList = () => api.get(url.GET_ROLE_LIST, null);
export const addRoleList = (data: any) => api.create(url.ADD_ROLE_LIST, data);
export const updateRoleList = (data: any) => api.update(url.UPDATE_ROLE_LIST, data);
export const deleteRoleList = (role: any) => api.delete(url.DELETE_ROLE_LIST, {headers: {role}});

// Permissions
export const getPermissionList = () => api.get(url.GET_PERMISSION_LIST, null);
export const addPermissionList = (data: any) => api.create(url.ADD_PERMISSION_LIST, data);
export const updatePermissionList = (data: any) => api.update(url.UPDATE_PERMISSION_LIST, data);
export const deletePermissionList = (permission: any) => api.delete(url.DELETE_PERMISSION_LIST, {headers: {permission}});

// Workflows
export const getWorkflowList = () => api.get(url.GET_WORKFLOW_LIST, null);
export const addWorkflowList = (data: any) => api.create(url.ADD_WORKFLOW_LIST, data);
export const updateWorkflowList = (data: any) => api.update(url.UPDATE_WORKFLOW_LIST, data);
export const deleteWorkflowList = (workflow: any) => api.delete(url.DELETE_WORKFLOW_LIST, {headers: {workflow}});

// Project
export const getProjectList = () => api.get(url.GET_PROJECT_LIST, null);
export const addProjectList = (data: any) => api.create(url.ADD_PROJECT_LIST, data);
export const updateProjectList = (data: any) => api.update(url.UPDATE_PROJECT_LIST, data);
export const deleteProjectList = (project: any) => api.delete(url.DELETE_PROJECT_LIST, {headers: {project}});
