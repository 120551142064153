import { postJwtLogin } from "helpers/fakebackend_helper";
import { loginError, loginSuccess, logoutSuccess } from "./reducer";
import { ThunkAction } from "redux-thunk";
import { Action, Dispatch } from "redux";
import { RootState } from "slices";
import { APIClient } from "../../../helpers/api_helper";
import { AuthPayload, AuthResponse, LogoutResponse } from "../../../pages/types";
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import { environment } from "../../../environment";

const api = new APIClient();
export const loginUser = (
    user: AuthPayload,
    history: any
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch: Dispatch) => {
    try {
        let response: AuthResponse;
        if (environment.defaultAuth === "fake") {

            // @ts-ignore
            response = await postJwtLogin({
                username: user.username,
                password: user.password,
            });

            const expectedResponse: AuthResponse = {
                body: {
                    user: response.body.user,
                    refreshToken: response.body.refreshToken,
                    accessToken: response.body.accessToken,
                },
                respCode: response.respCode,
                message: 'successful',
            }

            dispatch(loginSuccess(expectedResponse));
            history("/");

            return;
        }

        // @ts-ignore
        response = await api.login({
            username: user.username,
            password: user.password,
        });

        if (response) {
            localStorage.setItem("authUser", JSON.stringify(response.body.user));

            dispatch(loginSuccess(response));
            history("/");
        }
    } catch (error) {
        console.log("Auth login error: ", error)
        dispatch(loginError(error));
    }
};

export const logoutUser = () => async (dispatch: Dispatch) => {
    try {
        if (environment.defaultAuth === "fake") {
            const expectedResponse: LogoutResponse = {
                body: null,
                respCode: 600,
                message: 'successful',
            }
            dispatch(logoutSuccess(expectedResponse));

            return;
        }


        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            let fireBaseBackend = await getFirebaseBackend();
            const response = fireBaseBackend.logout;
            dispatch(logoutSuccess(response));

            return;
        }

        const response: LogoutResponse = await api.logout();
        dispatch(logoutSuccess(response));
    } catch (error) {
        dispatch(loginError(error));
    }
}
