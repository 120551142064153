import React from "react";
import UserList from "../pages";
import AddUpdateUser from "../pages/add_update";

interface RouteObject {
  path: string;
  component: React.ComponentType<any>;
  exact?: boolean;
}

const userRoutes: Array<RouteObject> = [
  {path: '/apps-users-list', component: UserList},
  {path: '/apps-users-list/add', component: AddUpdateUser},
  {path: '/apps-users-list/update/:id', component: AddUpdateUser},
];

export default userRoutes;
