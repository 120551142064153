import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    addTenantList as addTenantListApi,
    deleteTenantList as deleteTenantListApi,
    getSingleTenant as getSingleTenantApi,
    getTenantList as getTenantListApi,
    updateTenantList as updateTenantListApi,
} from "../../../../helpers/backend_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { GetTenantPayload } from "../types/tenant";
import { AxiosError } from "axios";

export const getTenantList = createAsyncThunk<
    any,
    GetTenantPayload,
    { rejectValue: any }
>(
    "tenants/getList", async (payload, {rejectWithValue}) => {
        try {
            const response = await getTenantListApi(payload);

            console.log("Response: ", response)

            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    });

export const getSingleTenant = createAsyncThunk("tenants/getSingle", async (id: string) => {
    try {
        const response = await getSingleTenantApi(id);

        console.log("Single Employee Response: ", response)

        return response;
    } catch (error: any) {
        const err: AxiosError<any> = error;

        if (!err.response) {
            return err
        }

        return err;
    }
});

export const addTenantList = createAsyncThunk("tenants/add", async (event: any) => {
    try {
        const response = addTenantListApi(event);
        const data = await response;
        toast.success("Tenant Added Successfully", {autoClose: 2000});
        return data;
    } catch (error) {
        toast.error("Tenant Added Failed", {autoClose: 2000});
        return error;
    }
});

export const updateTenantList = createAsyncThunk("tenants/update", async (event: any) => {
    try {
        const response = updateTenantListApi(event);
        const data = await response;
        toast.success("Tenant updated Successfully", {autoClose: 2000});
        return data;
    } catch (error) {
        toast.error("Tenant updated Failed", {autoClose: 2000});
        return error;
    }
});
export const deleteTenantList = createAsyncThunk("tenants/delete", async (event: any) => {
    try {
        const response = deleteTenantListApi(event);
        toast.success("Tenant deleted Successfully", {autoClose: 2000});
        return response;
    } catch (error) {
        toast.error("Tenant deleted Failed", {autoClose: 2000});
        return error;
    }
});
