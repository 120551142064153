const PermissionsData = [
    {
        id: "8b476a47-40e8-4909-bf58-cfd072b5899d",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "tenantMng",
        name: "wp_create_tenant",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "8c42355e-40e8-4909-bf58-cfd072b5899d",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "tenantMng",
        name: "wp_view_tenant",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "cfd072b5899d-40e8-4909-bf58-8b476a47",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "tenantMng",
        name: "wp_update_tenant",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "8b476a47-40e8-bf58-4909-cfd072b5899d",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "tenantMng",
        name: "wp_delete_tenant",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "8b476a47-40e8-4909-bf58-cfd072b5899d",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "officeMng",
        name: "wp_create_office",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "8c42355e-40e8-4909-bf58-cfd072b5899d",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "officeMng",
        name: "wp_view_office",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "cfd072b5899d-40e8-4909-bf58-8b476a47",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "officeMng",
        name: "wp_update_office",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "8b476a47-40e8-bf58-4909-cfd072b5899d",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "officeMng",
        name: "wp_delete_office",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "8b476a47-40e8-4909-bf58-cfd072b5899d",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "roleMng",
        name: "wp_create_role",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "8c42355e-40e8-4909-bf58-cfd072b5899d",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "roleMng",
        name: "wp_view_role",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "cfd072b5899d-40e8-4909-bf58-8b476a47",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "roleMng",
        name: "wp_update_role",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "8b476a47-40e8-bf58-4909-cfd072b5899d",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "roleMng",
        name: "wp_delete_role",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "8b476a47-40e8-4909-bf58-cfd072b5899d",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "userMng",
        name: "wp_create_user",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "8c42355e-40e8-4909-bf58-cfd072b5899d",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "userMng",
        name: "wp_view_user",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "cfd072b5899d-40e8-4909-bf58-8b476a47",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "userMng",
        name: "wp_update_user",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "8b476a47-40e8-bf58-4909-cfd072b5899d",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "userMng",
        name: "wp_delete_user",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "8b476a47-40e8-4909-bf58-cfd072b5899d",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "employeeMng",
        name: "wp_create_employee",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "8c42355e-40e8-4909-bf58-cfd072b5899d",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "employeeMng",
        name: "wp_view_employee",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "cfd072b5899d-40e8-4909-bf58-8b476a47",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "employeeMng",
        name: "wp_update_employee",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "8b476a47-40e8-bf58-4909-cfd072b5899d",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "employeeMng",
        name: "wp_delete_employee",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },

    {
        id: "8b476a47-40e8-4909-bf58-cfd072b5899d",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "attendanceMng",
        name: "wp_create_attendance",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "8c42355e-40e8-4909-bf58-cfd072b5899d",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "attendanceMng",
        name: "wp_view_attendance",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "cfd072b5899d-40e8-4909-bf58-8b476a47",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "attendanceMng",
        name: "wp_update_attendance",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "8b476a47-40e8-bf58-4909-cfd072b5899d",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "attendanceMng",
        name: "wp_delete_attendance",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },

    {
        id: "8b476a47-40e8-4909-bf58-cfd072b5899d",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "leaveMng",
        name: "wp_create_leave",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "8c42355e-40e8-4909-bf58-cfd072b5899d",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "leaveMng",
        name: "wp_view_leave",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "cfd072b5899d-40e8-4909-bf58-8b476a47",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "leaveMng",
        name: "wp_update_leave",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
    {
        id: "8b476a47-40e8-bf58-4909-cfd072b5899d",
        tenantId: "97341a6e-53e4-473f-8281-33e0ae30249c",
        roleId: "8c42355e-9583-461c-bda6-2b7a8379fe71",
        moduleName: "leaveMng",
        name: "wp_delete_leave",
        description: null,
        isActive: true,
        createdAt: "2024-06-21T17:17:43.000Z",
        updatedAt: "2024-06-21T17:17:43.000Z",
    },
];

export { PermissionsData };
