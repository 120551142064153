import React from "react";
import ProjectList from "../pages";
import AddUpdateProject from "../pages/add_update";

interface RouteObject {
  path: string;
  component: React.ComponentType<any>;
  exact?: boolean;
}

const projectRoutes: Array<RouteObject> = [
  {path: '/apps-projects-list', component: ProjectList},
  {path: '/apps-projects-list/add', component: AddUpdateProject},
  {path: '/apps-projects-list/update/:id', component: AddUpdateProject},
];

export default projectRoutes;
