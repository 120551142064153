import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    addLeaveManageHR as addLeaveManageHRApi,
    deleteLeaveManageHR as deleteLeaveManageHRApi,
    getLeaveManageEmployee as getLeaveManageEmployeeApi,
    getLeaveManageHR as getLeaveManageHRApi,
    updateLeaveManageHR as updateLeaveManageHRApi
} from "../../../helpers/backend_helper";
import 'react-toastify/dist/ReactToastify.css';
import { GetEmployeeLeavePayload } from "../../../pages/HRManagement/types";
import { toast } from "react-toastify";

export const getLeaveManageEmployee = createAsyncThunk<
    any, GetEmployeeLeavePayload, { rejectValue: any }
>("hrManagement/getLeaveManageEmployee", async (payload, {rejectWithValue}) => {
    try {
        return await getLeaveManageEmployeeApi(payload);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getLeaveManageHR = createAsyncThunk<
    any, GetEmployeeLeavePayload, { rejectValue: any }
>("hrManagement/getLeaveManageHR", async (payload, {rejectWithValue}) => {
    try {
        return await getLeaveManageHRApi(payload);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const addLeaveManageHR = createAsyncThunk("hrManagement/addLeaveManageHR", async (event: any) => {
    try {
        const response = await addLeaveManageHRApi(event);
        toast.success("Leave Added Successfully", {autoClose: 2000});
        return response;
    } catch (error) {
        toast.error("Leave Added Failed", {autoClose: 2000});
        return error;
    }
});

export const updateLeaveManageHR = createAsyncThunk("hrManagement/updateLeaveManageHR", async (event: any) => {
    try {
        const response = await updateLeaveManageHRApi(event);
        toast.success("Leave updated Successfully", {autoClose: 2000});
        return response;
    } catch (error) {
        toast.error("Leave updated Failed", {autoClose: 2000});
        return error;
    }
});

export const deleteLeaveManageHR = createAsyncThunk("hrManagement/deleteLeaveManageHR", async (event: any) => {
    try {
        const response = deleteLeaveManageHRApi(event);
        toast.success("Leave deleted Successfully", {autoClose: 2000});
        return response;
    } catch (error) {
        toast.error("Leave deleted Failed", {autoClose: 2000});
        return error;
    }
});
