export const VIEW_EMPLOYEE_LIST = "";
export const VIEW_EMPLOYEE = "";
export const UPDATE_EMPLOYEE_LIST = "";
export const DELETE_EMPLOYEE_LIST = "";
export const APPROVE_EMPLOYEE_LIST = "";


export const titles = [
  {
    value: "mr",
    label: "Mr"
  },
  {
    value: "mrs",
    label: "Mrs"
  },
  {
    value: "ms",
    label: "Miss"
  },
  {
    value: "other",
    label: "Other"
  }
];

export const maritalStatuses = [
  {
    value: "single",
    label: "Single",
  },
  {
    value: "married",
    label: "Married",
  },
  {
    value: "divorced",
    label: "Divorced",
  },
  {
    value: "widowed",
    label: "Widowed",
  },
];

export const gender = [
  {
    value: "M",
    label: "Male",
  },
  {
    value: "F",
    label: "Female",
  }
];
